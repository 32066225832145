<template>
  <modal title="error" name="modal" :width="365"
         :height="152" :adaptive="true">
    <div @click="hideModal" class="pop-up-container">
      <p class="text-center text-modal">
       {{message !== "" ? message : $store.state.responseDataMessage}}</p>
      <div class="pop-up"><p class="pop-up-close">OK</p></div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "modalAlert",
  props : { message : String },
  methods : {
    showModal() {
      this.$modal.show('modal');
      console.log("test");
    },
    hideModal() {
      this.$modal.hide('modal');
    },
  },
}
</script>

<style scoped>
@media screen and (min-width: 75em) {
  .intro-container p {
    font-size: 16px;
  }
}

.intro-container p {
   margin-top: 0;
}
</style>