import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import $ from "jquery";
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        successAccess: false,
        error: false,
        questionsList: [],
        categoriesList: [],
        pageCount: null,
        partnersList: [],
        savedAnswers: [],
        textFieldList: [],
        questionnaireId: '',
        languageActive: '',
        language: '',
        resultCompleteComputeQuestionnaire: [],
        nameUserGuest: "",
        surnameUserGuest: "",
        guestHub: "",
        elaborateGuestResponse: null,
        computeQuestionnaireId: "",
        savedExtraAnswers: [],
        completeComputeQuestionnaire: [],
        oneMinutePitchQuestionnaires: [],
        responseDataErrorCode: "",
        responseDataMessage: "",
        user: {
            name: "",
            surname: "",
            email: "",
            computeQuestionnaireId: "",
            token: null,
        }
    },
    mutations: {
        successMutation(state, payload) {
            state.successAccess = true;
            state.user.token = payload.accessToken;
            state.user.name = payload.user.name;
            state.user.surname = payload.user.surname;
            state.user.email = payload.user.email;
            state.user.computeQuestionnaireId = payload.user.computeQuestionnaireId;
        },
        userIdMutation(state, userId) {
            state.userId = userId;
        },
        isFromLoginMutation(state) {
            state.isFromLogin = true;
        },
        errorMutation(state, err) {
            state.error = true;
            state.responseDataErrorCode = err.response.data.errorCode;
            state.responseDataMessage = err.response.data.message;
        },
        clearErrorsMutation(state) {
            state.error = false;
        },

        numbersPagesMutation(state, payload) {
            this.state.pageCount = payload;
        },
        setElaborateGuestResponseMutation(state, payload) {
            this.state.elaborateGuestResponse = payload;
            this.state.computeQuestionnaireId = payload.id;
        },
        setCompleteComputeQuestionnaireMutation(state, payload) {
            this.state.completeComputeQuestionnaire = payload;
            this.state.resultCompleteComputeQuestionnaire = payload.result;
        },
        setComputeQuestionnaireMutation(state, payload) {
            this.state.completeComputeQuestionnaire = payload;
            this.state.resultCompleteComputeQuestionnaire = payload.result;
        },
        setQuestionnaireMutation(state, payload) {
            this.state.categoriesList = payload.categories;
            this.state.questionsList = payload.questions;
            this.state.oneMinutePitchQuestionnaires = payload.oneMinutePitchQuestionnaires;
            this.state.questionnaireId = payload.id;
        },
        logoutMutation() {
            this.state.user = {};
            this.state.completeComputeQuestionnaire = [];
            this.resultCompleteComputeQuestionnaire = [];
        }
    },
    actions: {
        async getQuestionnaireAction(context) {
            return await axios.post(process.env.VUE_APP_BASEURL, {method: "getQuestionario"}, {}).then(res => {
                console.log('risultato', res)
                let payload = res.data.root.payload;
                context.commit('setQuestionnaireMutation', payload);
            });
        },
        async postQuestionnaireAction(context, payload) {
            const resultQuestionnaire = payload.json;
            const router = payload.router;
            const headers = {
                'Content-Type': 'application/json'
            }
            await axios.post(process.env.VUE_APP_BASEURL, {method: 'putQuestionario', risposta: resultQuestionnaire}, {headers})
                .then((res) => {
                    // putQuestionario, risposta:
                    let payload = res.data;
                    console.log('putQuestionario ->>>>>', payload)
                    context.commit('setElaborateGuestResponseMutation', payload);
                    $('html, body').animate({scrollTop: 0}, 300);
                    router.push('/dashboardGuest');
                }).catch((err) => {
                        if (err.response) {
                            console.log(err.response)
                        }
                    }
                )

        },
        async completeComputeQuestionnaireAction(context, payload) {
            let self = this;
            const resultExtraQuestionnaire = payload.json;
            const router = payload.router;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + self.state.user.token,
            }
            await axios.post(process.env.VUE_APP_BASEURL + process.env.VUE_APP_COMPLETE_COMPUTE_QUESTIONNAIRE, resultExtraQuestionnaire, {headers: headers}).then((res) => {
                let payload = res.data.payload;
                context.commit('setCompleteComputeQuestionnaireMutation', payload);
                $('html, body').animate({scrollTop: 0}, 300);
                router.push('/dashboard');
            }).catch((err) => {
                if (err.response) {
                    console.log(err.response);
                }
            })
        },
        async getComputedQuestionnaire(context, router) {
            let self = this;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + self.state.user.token,
            }

            try {
                await axios.get(`${process.env.VUE_APP_BASEURL}${process.env.VUE_APP_GET_COMPUTED_QUESTIONNAIRE}?id=${self.state.user.computeQuestionnaireId}`, {headers}).then((res) => {
                    let payload = res.data.payload;
                    context.commit('setComputeQuestionnaireMutation', payload);
                    context.commit('setElaborateGuestResponseMutation', payload);
                    router.push('/dashboard');
                })
            } catch (e) {
                console.log(e)
            }
        },
        async loginAction(context, payload) {

            await axios.post(process.env.VUE_APP_BASEURL, {
                method: 'login',
                email: payload.email,
                password: payload.password,
            }).then((res) => {
                let payloadRes = res.data.payload;
                context.commit('successMutation', payloadRes);
            }).catch((error) => {
                console.log(error);
                context.commit('errorMutation', error);
            })
        },
        async registerAction(context, payload) {
            let router = payload.router;
            await axios.post(process.env.VUE_APP_BASEURL + process.env.VUE_APP_REGISTRATION, {
                name: payload.name,
                surname: payload.surname,
                password: payload.password,
                computeQuestionnaireId: this.state.computeQuestionnaireId,
                email: payload.email,
            }).then((res) => {
                let payload = res.data.payload;
                context.commit('successMutation', payload);
                router.push('/extraForm');
            })
                .catch(error => {
                    console.log(error.response.data.errorCode);
                    console.log(error.response.data.message);
                    context.commit('errorMutation', error);
                });
        },
        /*        autoLoginAction(context) {
                    const token = localStorage.getItem('token');
                    if (!token) {
                        return
                    }
                    const userId = localStorage.getItem('userId');
                    context.commit('loginTokenMutation', token);
                    context.commit('userIdMutation', userId);
                },*/
        numbersPagesAction(context) {
            let page = 0;
            let pageArray = [];
            for (const [i, question] of context.state.questionsList.entries()) {
                if (question.page !== page || i === 0) {
                    page = question.page;
                    if (!pageArray.includes(page)) {
                        pageArray.push(page);
                    }
                }
            }
            return pageArray.length;
        },
        getPartnersAction(context) {
            if (context.state.completeComputeQuestionnaire !== undefined && context.state.completeComputeQuestionnaire.length !== 0) {
                for (const partner of context.state.completeComputeQuestionnaire.partners) {
                    context.state.partnersList.push(partner.category);
                }
                if (context.state.partnersList !== undefined && context.state.partnersList.length !== 0) {
                    context.state.partnersList.splice(0, 0, "All");
                    context.state.partnersList = context.state.partnersList.filter(function (elem, index, self) {
                        return index === self.indexOf(elem);
                    });
                }

            }
        },
    },
});


