<template>
  <div class="container p-2">
    <div class="card" style="background-color: transparent">
      <div class="row" style="border: 1px solid white; border-radius: 5px; padding: 10px; background-color: #000">
        <div class="col-md-4" style="padding: 2px">
          <div class="responsive-mobile-card d-flex justify-content-center">
            <img class="pb-3 pt-3" :src="insertImage()">
          </div>
        </div>
        <div class="col-md-8">
          <div class="card-block" style="text-align: start">
            <p class="category-card-text">{{ category }}</p>
            <div class="flex-item-content-title cut-text ">{{ name }}</div>
            <div class="row">
              <div class="col-1 p-0">
                <img src="../assets/globe2.svg" alt="">
                <img src="../assets/mailbox.svg" alt="">
              </div>
              <div class="col-10">
                <div class="cut-text">
                <a :href="website" target="_blank" class="card-text">{{ website }}</a>
                </div>
                <div class="card-text">{{ email }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>


<script>


export default {
  name: "CardEcosystem",
  props: {name: String, category: String, email: String, website: String},

  data: function () {
    return {}
  },
  methods: {
    insertImage() {
      let img = 'DefaultCategory.svg';
      switch (this.category) {
        case 'Incubator/Accelerator':
          img = 'IncubatorAccelerator.svg';
          break;
        case 'Foundation/Association':
          img = 'FoundationAssociation.svg';
          break;
        case 'Academy':
          img = 'Academy.svg';
          break;
        case 'Corporate':
          img = 'Corporate.svg';
          break;
        case 'Inspiration':
          img = 'Inspiration.svg';
          break;
        case 'Investor':
          img = 'Investors.svg';
          break;
        case 'Research':
          img = 'Research.svg';
          break;
      }
      return require('../assets/' + img);
    }
  }
}
</script>

<style scoped>

</style>