<template>
  <div>
    <header>
      <div class="top-nav">
        <div class="top-container">
          <img src="@/assets/Vector.png" class="bf-icon"/>
          <div class="menu-translate">
            <div class="dropdown mobile">
              <button style="color: #FFFFFF" class="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <a style="font-size: 14px">{{ this.$store.state.languageActive }}</a>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a style="font-size: 14px" @click="changeLanguage()"
                   class="dropdown-item">
                  {{ this.$store.state.language }}
                </a>
              </div>
            </div>
            <i class="fas fa-bars menu-mobile" @click="toggleMenu()"></i>
          </div>
          <nav class="menu-desktop desktop">
            <ul>
              <li v-scroll-to="'#TestYourIdea'"><a href="#">{{ t('Test your idea') }}</a></li>
              <li v-scroll-to="'#HowItWorks'"><a href="#">{{ t('How it works') }}</a></li>
              <li v-scroll-to="'#Network'"><a href="#">{{ t('Network') }}</a></li>
              <li v-scroll-to="'#Mission'"><a href="#">{{ t('Mission') }}</a></li>
            </ul>
          </nav>
          <div class="row align-items-center desktop">
            <ul class="p-0 m-0 align-self-center">
              <li class="dropdown">
                <button style="color: #FFFFFF" class="btn dropdown-toggle" type="button" id="dropdownMenuButtonDesktop"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <a href="#">{{ this.$store.state.languageActive }}</a>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonDesktop">
                  <a @click="changeLanguage()"
                     class="dropdown-item">
                    {{ this.$store.state.language }}
                  </a>
                </div>
              </li>
            </ul>
            <div v-if="this.$store.state.user.name" class="row ml-2 align-items-center onLogout">
              <div style="color: white; margin-right: 10px">
                {{ this.$store.state.user.name + ' ' + this.$store.state.user.surname }}
              </div>
              <div class="nameCircle mr-4">
                {{
                  this.$store.state.user.name.charAt(0).toUpperCase() + this.$store.state.user.surname.charAt(0).toUpperCase()
                }}
              </div>
              <div @click="logout">
                <img src="@/assets/logout.png" alt="logout" class="logout-icon">
                <p class="logout-text d-inline" style="margin-left: 8px;">Logout</p>
              </div>
            </div>
            <modal-sign-in v-else v-bind:text-botton="t('Sign In')"></modal-sign-in>
          </div>
        </div>
      </div>
    </header>

    <main :class="this.menu ? 'scroll' : ''">
      <section id="TestYourIdea" class="intro-container pt-89">
        <h1>{{ t('Do you have a great idea?') }}<br>{{ t('Are you launching a Start-up?') }}</h1>
        <p>{{
            t('Many ideas remain such for fear of failure, or because most of us don’t know how to launch and scale a startup')
          }}</p>

        <form v-show="!this.$store.state.user.name" class="form-inline">
          <!-- <div class="form-group mb-2">
            <input type="text" class="form-control-plaintext" id="staticEmail2" :placeholder="t('Your email here')" v-model="register">
          </div> -->
          <button type="submit" class="button-primary mb-3" @click="startForm()">{{ t('Start') }}</button>

          <!--          <p class="success" v-if="registerSuccess">{{ t('Thanks!') }}</p>
                    <p class="error" v-if="registerError">{{ t('Already registered!') }}</p>-->
        </form>
      </section>
      <section id="HowItWorks" class="description-container pt-89">
        <h2>{{ t('How does StarFounder work?') }}</h2>
        <div class="container-lg functionalities">
          <div class="row">
            <div class="functionality col-md-4 col-sm-12">
              <div class="functionality-title">
                <img src="@/assets/star.png" class="fn-image"/>
                <h3>{{ t('Evaluate') }}</h3>
              </div>
              <p>{{ t('Identify the viability of your idea using our quick survey') }}</p>
            </div>
            <div class="functionality col-md-4 col-sm-12">
              <div class="functionality-title">
                <img src="@/assets/lens.png" class="fn-image"/>
                <h3>{{ t('Discover') }}</h3>
              </div>
              <p>{{ t('Ready to start? Check out your profile and StarFounder page') }}</p>
            </div>
            <div class="functionality col-md-4 col-sm-12">
              <div class="functionality-title">
                <img src="@/assets/pc.png" class="fn-image"/>
                <h3>{{ t('Explore') }}</h3>
              </div>
              <p>{{ t('Explore our network. We are stronger together!') }}</p>
            </div>
            <div class="functionality col-md-4 col-sm-12">
              <div class="functionality-title">
                <img src="@/assets/lamp.png" class="fn-image"/>
                <h3>{{ t('Get inspired') }}</h3>
              </div>
              <p>{{ t('Learn about and be inspired by the best startups in your sector') }}</p>
            </div>
            <div class="functionality col-md-4 col-sm-12">
              <div class="functionality-title">
                <img src="@/assets/volume.png" class="fn-image"/>
                <h3>{{ t('Promote') }}</h3>
              </div>
              <p>{{ t('Share your "one minute pitch" and build your network') }}</p>
            </div>
            <div class="functionality col-md-4 col-sm-12">
              <div class="functionality-title">
                <img src="@/assets/space.png" class="fn-image"/>
                <h3>{{ t('Next steps') }}</h3>
              </div>
              <p>{{ t('Keep in touch and stay updated on the Founder Tools we are launching shortly') }}</p>
            </div>
          </div>
        </div>
      </section>
      <div class="clearfix"></div>
      <div class="dati-container pt-89" id="Network">
        <div class="container">
          <div class="row">
            <div class="col">
              <h2>+1000</h2>
              <p style="font-size: 25px"><b>{{
                  t('Hey there! We are ready to connect founders to a global network of over 1000 connections across 8 Hubs. What is an Hub? Is a local community of entrepreneurs, startups, accelerators, institutions, venture capitalist and more')
                }}</b></p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12 text-left">
              <h3>{{ t('We work hard on a daily basis to grow our StarFounder Ecosystem Map') }}</h3>
              <p class="littlemb">{{
                  t('Are you interested in connecting with your local community taking it to the next level? StarFounder is hiring ambitious Ambassadors to help us achieve our goals')
                }}</p>
            </div>
            <div class="col-md-8 col-sm-12 text-center">
              <div class="row" :key="animRefr">
                <div class="col-md-4 col-6 dati-item">
                  <Roller text="14" :transition="2"></Roller>
                  <p><b>{{ t('StarFounder Hub') }}</b></p>
                </div>
                <div class="col-md-4 col-6 dati-item">
                  <Roller class="numBaseHub" text="+250" :transition="2"></Roller>
                  <p><b>{{ t('Incubators and accelerators') }}</b></p>
                </div>
                <div class="col-md-4 col-6 dati-item">
                  <Roller text="+200" :transition="2"></Roller>
                  <p><b>{{ t('Investors') }}</b></p>
                </div>
                <div class="col-md-4 col-6 dati-item">
                  <Roller text="+400" :transition="2"></Roller>
                  <p><b>{{ t('Startups') }}</b></p>
                </div>
                <div class="col-md-4 col-6 dati-item">
                  <Roller text="+180" :transition="2"></Roller>
                  <p><b>{{ t('Think Tank, Research Centers and University') }}</b></p>
                </div>
                <div class="col-md-4 col-6 dati-item">
                  <Roller text="+100" :transition="2"></Roller>
                  <p><b>{{ t('Corporate') }}</b></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info-container text-left pt-89" id="Mission">
        <div class="container">
          <div class="row info-intro">
            <div class="col-md-6 col-12 info-intro-item ">
              <h2>{{ t('Foster innovation and entrepreneurship globally using simplified digital tools.') }}</h2>
              <p>{{
                  t('StarFounder was created to generate a positive impact in society. We believe that supporting entrepreneurship is the enabling factor for achieving economic, social and sustainable development goals')
                }}</p>
            </div>
            <div class="col-md-6 col-12">
              <img src="../assets/creative-team-animate.svg" alt="">
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-12">
              <h3>{{ t('Community') }}</h3>
              <p>{{
                  t('Free, Secure and Inclusive, StarFounder is the global community for those who want to build and grow their business idea')
                }}</p>
            </div>
            <div class="col-md-4 col-12">
              <h3>{{ t('StarFounder.com') }}</h3>
              <p>{{ t('How we make our digital solutions and skills available to passionate founders') }}</p>
            </div>
            <div class="col-md-4 col-12">
              <h3>{{ t('StarFounder Factory') }}</h3>
              <p>{{
                  t('We are a tech company focused on providing a support network of Start-up communities and Entrepreneurs who believe in our mission and values')
                }}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- section newsletter
    <div class="big-azure-section">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2>{{ t('#staytuned with our newsletter') }}</h2>
            <form class="form-inline">
              <div class="form-group mb-2">
                <input type="text" class="form-control-plaintext" id="staticEmail2" :placeholder="t('Your email here')"
                       v-model="newsletter">
              </div>

              <button type="submit" class="button-primary" @click="registerNewsletter">{{ t('Subscribe') }}</button>
              <p class="success white" v-if="newsletterSuccess">{{ t('Thanks!') }}</p>
              <p class="error white" v-if="newsletterError">{{ t('Already registered!') }}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
    -->
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-12 footer-item">
            <div class="copy"><img src="@/assets/logo_starfounder.svg" class="cubeimg"/> 2023 &copy;</div>
          </div>
          <div class="col-md-6 col-12 footer-item">
            <a href="#" type="application/pdf" title="Cookie Policy">Cookie Policy</a>
            <a href="https://www.starfounder.it/pdf/20211020_PrivacyPolicy.pdf" target="_blank" type="application/pdf"
               title="Privacy Policy">Privacy Policy</a>
          </div>
        </div>
      </div>


    </footer>
    <div :class="this.menu ? 'menu-open open' : 'menu-open'">
      <div class="menu-navbar">
        <div class="container">
          <img src="@/assets/Vector.png" class="bf-icon"/>
        </div>
        <i class="fas fa-times" @click="toggleMenu()"></i>
      </div>
      <div class="container">
        <div class="row">
          <div class="col">
            <ul>
              <li @click="toggleMenu()" v-scroll-to="'#TestYourIdea'">{{ t('Test your idea') }}</li>
              <li @click="toggleMenu()" v-scroll-to="'#HowItWorks'">{{ t('How it works') }}</li>
              <li @click="toggleMenu()" v-scroll-to="'#Network'">{{ t('Network') }}</li>
              <li @click="toggleMenu()" v-scroll-to="'#Mission'">{{ t('Mission') }}</li>
              <modal-sign-in v-on:closeMenu="toggleMenu" class="mt-4" v-bind:text-botton="t('Sign In')"></modal-sign-in>
            </ul>
            <!--<div class="little-button">Sign Up</div>
            <div class="little-button switched">Log In</div>-->
          </div>
        </div>
      </div>
    </div>
    <loader style="z-index: 9999999" v-if="this.loader" object="#f7b847" color1="#ffffff" color2="#17fd3d" size="6"
            speed="2" bg="#343a40"
            objectbg="#999793" opacity="80" :disableScrolling="false" name="dots"></loader>
  </div>

</template>

<script>
import axios from 'axios';
import $ from "jquery";
import ModalSignIn from "../components/ModalSignIn"
import Roller from "vue-roller";


export default {
  name: 'Home',
  components: {ModalSignIn, Roller},

  data: function () {
    return {
      loader: false,
      newsletter: '',
      register: '',
      menu: false,
      registerSuccess: false,
      registerError: false,
      newsletterSuccess: false,
      newsletterError: false,
      animRefr: false,

    }
  },

  created() {
    if (navigator.language === 'it-IT') {
      this.$store.state.languageActive = "IT";
      this.$store.state.language = "EN";
      this.$translate.setLang('italian');
    } else {
      this.$store.state.languageActive = "EN";
      this.$store.state.language = "IT";
      this.$translate.setLang('english');

    }
    if (this.$store.state.languageActive === "IT") {
      this.$translate.setLang('italian');
    } else {
      this.$translate.setLang('english');
    }
    setInterval(() => {
      this.animRefr = !this.animRefr
    }, 10000);
  },

  methods: {
    toggleMenu() {
      this.menu = !this.menu
    },

    changeLanguage() {
      if (this.$store.state.language === 'EN') {
        let temp = this.$store.state.languageActive;
        this.$store.state.languageActive = this.$store.state.language;
        this.$store.state.language = temp;
        this.$translate.setLang('english');
      } else {
        let temp = this.$store.state.languageActive;
        this.$store.state.languageActive = this.$store.state.language;
        this.$store.state.language = temp;
        this.$translate.setLang('italian');
      }
    },
    startForm() {
      $('html, body').animate({scrollTop: 0}, 300);
      this.$router.push('/form');
    },
    logout() {
      this.loader = true;
      this.$router.push('#');
      this.$store.commit("logoutMutation");
      this.loader = false;
    },
    async registerNewsletter(evt) {
      evt.preventDefault()

      this.newsletterSuccess = false
      this.newsletterError = false

      try {
        await axios.post('https://starfounder.it/api/user/newsletter', {
          email: this.newsletter
        })
        this.newsletterSuccess = true
        this.newsletter = ''
      } catch (e) {
        this.newsletterError = true
      }
    }
  }
}
</script>

<style scoped>
.dropdown-item:active {
  background-color: transparent;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.dropdown-menu {
  background-color: #303030;
  min-width: 2rem;
}

.dropdown-item:hover {
  background-color: transparent;
}

.dropdown-item {
  color: white;
}

.dropdown-toggle::after {
  border-top: .2em solid;
  border-right: .2em solid transparent;
  border-left: .2em solid transparent;
}


</style>
