<template>
  <div class="no-questions-container">
    <div class="text-center">
      <img src="@/assets/no-service.png" alt="no service">
    </div>
    <p class ="no-questions-text">{{ t('OOPS! Something went wrong. Try again later.') }} </p>
  </div>
</template>

<script>
export default {
  name: "NoServicesAvailable"
}
</script>

<style scoped>

</style>