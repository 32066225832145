<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col">
          <div v-if="oneMinutePitchQuestionnaires.length !== 0" class="section">
            <h1> {{ t('We still need some info...') }}</h1>
            <div v-for="(item, index) in oneMinutePitchQuestionnaires" v-bind:key="index">
              <h3>{{ checkIndex(index) + " — " + checkLanguage(item.title) }}</h3>

              <div v-if="item.type === Type.SINGLE_CHOICE"
                   :class="item.answers.length > 3 ? 'question-ans' : 'question-ans-col'">
                <div class="ans" v-for="(ans, key) in item.answers" v-bind:key="key"
                     :style="item.answers.length > 3  ? 'width: 47%' : '' ">
                  <div class="answer grey"
                       v-if="controlAnswer(ans.title) !== false && !getActiveAnswer(item.id, ans.id, index) "
                       @click="setActiveAnswer(item.id, ans.id, $event.target.value, item.type)">
                    {{ checkLanguage(ans.title) }}
                  </div>

                  <div
                      :style="ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD  ? 'padding:2px !important;'  : ''"
                      :class="(ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD) && item.answers.length > 3  ? 'container-text-field-row': 'container-text-field-col'"
                      class="answer active "
                      v-if="controlAnswer(ans.title) !== false && getActiveAnswer(item.id, ans.id, index)"
                      @click="setActiveAnswer(item.id, ans.id, $event.target.value, item.type)">
                      <span class="span-text" :class="item.answers.length < 3 ? 'align-center': ''"
                            v-if="ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD">{{
                          checkLanguage(ans.title)
                        }}
                      </span>

                    <div v-if="ans.type !== Type.TEXT_FIELD && item.type !== Type.TEXT_FIELD"> <span>{{
                        checkLanguage(ans.title)
                      }}</span>
                    </div>

                    <div v-if="ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD"
                         :class="(ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD) && item.answers.length < 3  ? 'text-field-col' : 'text-field-row'">
                      <div class="box-tf">
                        <img v-if="ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD"
                             src="@/assets/pen.svg" class="pen-icon" alt="pen"/>
                        <input @change="setActiveAnswer(item.id, ans.id, $event.target.value, item.type)"
                               v-if="ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD" type="text"
                               placeholder="Your text here"
                               class="form-control-plaintext">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="item.type === Type.DROPDOWN" class="selectParent">
                <select :style="selected !== '' ? 'border: 1px solid #FDB827;' : ''" class="dropdown-container"
                        v-model="selected">
                  <option value="" selected disabled hidden>Scegli qui</option>
                  <option class="option-container" v-for="(dropAns, keyDrop) in item.answers" :key="keyDrop"
                          :value="{ansId: dropAns.id,
                          itemId: item.id,
                          itemType: item.type}">
                    {{ checkLanguage(dropAns.title) }}
                  </option>
                </select>
              </div>

              <div v-if="item.type === Type.FULL_TEXT_FIELD">
                <div class="ans" v-for="(ans, ansKey) in item.answers" v-bind:key="ansKey"
                     :style="item.answers.length > 3  ? 'width: 47%' : '' ">
                  <div :style="getActiveAnswer(item.id, ans.id, ansKey) ? 'border: 1px solid orange;': ''" class="text-area-box">
                    <img src="@/assets/pen.svg" class="pr-2 pt-1" alt="pen">
                    <textarea
                              @change="setActiveAnswer(item.id, ans.id, $event.target.value, item.type)"
                              maxlength="200"
                              class="text-area" rows="5">
                  </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="vh-100">
            <no-services-available></no-services-available>
          </div>
        </div>
      </div>
    </div>
    <loader v-if="loader" object="#f7b847" color1="#ffffff" color2="#17fd3d" size="6" speed="2" bg="#343a40" objectbg="#999793" opacity="80" :disableScrolling="false" name="dots"></loader>

    <div class="continue"
         :style="isActive ? 'background: linear-gradient(57.64deg, #FFFFFF 11.51%, #FDB827 88.91%)' : 'background: #767676'"
         @click="nextSection()">{{ t('Continue') }}
    </div>

    <modal-alert ref="modalAlert" :message="message"></modal-alert>

  </div>
</template>


<script>
import EnumType from '../utils/EnumType';
import modalAlert from '../components/modalAlert';
import NoServicesAvailable from "../components/NoServicesAvailable";

export default {
  name: 'Form',
  components: {NoServicesAvailable, modalAlert},
  data: function () {
    return {
      selected: '',
      message: '',
      messageChar: '',
      activeSection: null,
      oneMinutePitchQuestionnaires: [],
      selectedAnswer: [],
      questionsPage: [],
      isActive: false,
      loader: false,
      Type: {
        TEXT_FIELD: EnumType.TEXT_FIELD,
        SINGLE_CHOICE_OR_TEXT_FIELD: EnumType.SINGLE_CHOICE_OR_TEXT_FIELD,
        SINGLE_CHOICE: EnumType.SINGLE_CHOICE,
        DROPDOWN: EnumType.DROPDOWN,
        FULL_TEXT_FIELD: EnumType.FULL_TEXT_FIELD,
      },
      answerTextFieldTypeId: [],
      resultTot: {
        responses: []
      }
    }
  },
  created() {
    let self = this;
    if (self.$store.state.languageActive === "IT") {
      self.$translate.setLang('italian');
      self.messageChar = 'Per continuare è necessario inserire minimo 50 caratteri';
      self.messageAlert = 'Per continuare è necessario rispondere a tutte le domande';
    } else {
      self.$translate.setLang('english');
      self.messageChar = 'To continue you have to enter at least 50 characters';
      self.messageAlert = 'To continue you have to answer all questions';
    }
  },
  mounted() {
    let self = this;
    self.oneMinutePitchQuestionnaires = self.$store.state.oneMinutePitchQuestionnaires;
    console.log(self.oneMinutePitchQuestionnaires);
    self.oneMinutePitchQuestionnaires.sort((questionnaire1, questionnaire2) => questionnaire2.order - questionnaire1.order).reverse();
  },
  watch: {
    selected: function (e) {
      this.setActiveAnswer(e.itemId, e.ansId, '', e.itemType);
    },
    selectedAnswer: function (e) {
      if (e.length !== 0 && this.oneMinutePitchQuestionnaires.length !== 0) {
        if (e.length === this.oneMinutePitchQuestionnaires.length) {
          this.isActive = true;
        }
      } else {
        this.isActive = false;
      }
    },
    activeSection: function (newState, oldState) {
      if (this.selectedAnswer.length !== 0) {
        this.isActive = true;
      }
      this.questionsPage = [];
      if (newState !== oldState) {
        for (const question of this.questions) {
          if (question.page === newState) {
            this.questionsPage.push(question);
          }
        }
      }
    }
  },
  methods: {

    controlAnswer(title) {
      if (this.$store.state.languageActive === 'EN') {
        return !!title.en;
      } else {
        return !!title.it;
      }
    },
    async nextSection() {
      let self = this;
      let contQ = self.oneMinutePitchQuestionnaires.length;
      let contSelected = 0;
      self.loader=true;
      if (self.selectedAnswer != null) {
        // eslint-disable-next-line no-unused-vars
        for (const ansSelected of self.selectedAnswer) {
          contSelected++;
        }

        let isTextFieldEmpty = self.textFieldControl();

        if (contSelected !== contQ || isTextFieldEmpty) {
          self.message = self.messageAlert;
          self.$refs.modalAlert.showModal();
          self.loader = false
        } else {
          self.$store.state.savedExtraAnswers = self.selectedAnswer;
          const result = this.$store.state.savedExtraAnswers.map((answer) => {
            return {
              questionId: answer.questionId,
              answerId: answer.answerId,
              text: answer.text,
              type: answer.type
            }
          });
          self.resultTot.responses = self.resultTot.responses.concat(result);
          const json = JSON.stringify(self.resultTot);
          let payload = {
            json: json,
            router: self.$router,
          }
          await self.$store.dispatch('completeComputeQuestionnaireAction', payload).then(()=>{
            self.loader=false;
          });
        }
      }

    },
    textFieldControl() {
      let self = this;
      let isEmpty = false;
      self.answerTextFieldTypeId = [];
      for (const question of self.oneMinutePitchQuestionnaires) {
        for (const answersElement of question.answers) {
          if (question.type === self.Type.TEXT_FIELD || answersElement.type === self.Type.TEXT_FIELD) {
            self.answerTextFieldTypeId.push(answersElement.id);
          }
        }
      }
      for (const selectedAnswerElement of self.selectedAnswer) {
        for (let i = 0; i < this.answerTextFieldTypeId.length; i++) {
          if (selectedAnswerElement.answerId === self.answerTextFieldTypeId[i]) {
            if (selectedAnswerElement.text === '') {
              isEmpty = true;
            }
          }
        }
      }
      return isEmpty;
    },
    getActiveAnswer(questionId, answerId) {
      let temp = false;
      for (const el of this.selectedAnswer) {
        if (el.questionId === questionId) {
          temp = el.answerId === answerId;
        }
      }
      return temp;
    },
    setActiveAnswer(questionId, answerId, text = '', answerType) {
      if (answerType === this.Type.FULL_TEXT_FIELD && text.length < 50) {
        this.message = this.messageChar;
        this.$refs.modalAlert.showModal();
      } else {
        if (this.selectedAnswer.length === 0) {
          this.selectedAnswer.push({
            questionId: questionId,
            answerId: answerId,
            text: text,
            type: answerType,
          });
        } else {
          let index = 0;
          let exist = false;
          for (const el of this.selectedAnswer) {
            if (el.questionId === questionId) {
              exist = true;
              let temp = this.selectedAnswer;
              this.selectedAnswer = [];
              temp[index] = {
                questionId: questionId,
                answerId: answerId,
                text: text,
                type: answerType,
              };
              this.selectedAnswer = temp;
            }
            index++;
          }
          if (!exist)
            this.selectedAnswer.push({
              questionId: questionId,
              answerId: answerId,
              text: text,
              type: answerType,
            });

        }
      }
    },
    checkIndex(index) {
      index++;
      if (index < 9) return "0" + index;
      return index;
    },
    checkLanguage(title) {
      if (this.$store.state.languageActive === 'EN') {
        return title.en;
      } else {
        return title.it;
      }
    },
  }
}
</script>


<style scoped>
h1 {
  font-family: "Sora", sans-serif;
  font-size: 40px;
  line-height: 1.2;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -1px;
  text-align: left;
  background: #FDB827;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;

}

h3 {
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  color: #FFFFFF;
  letter-spacing: -1px;
  text-align: left;
}

input:focus {
  outline: none !important;
}

.form-control-plaintext {
  color: white;
}


@media (max-width: 30em) {
  .form-control-plaintext {
    padding: 0px;
    margin-left: 5px;
  }

  ::placeholder {
    font-size: 12px;
  }
}

@media (max-width: 25em) {
  .form-control-plaintext {
    padding: 0px;
    margin-left: 5px;
  }

  ::placeholder {
    font-size: 9px;
  }

}


</style>