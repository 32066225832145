<template>
  <div>
    <div class="top-nav">
      <div class="container nav-bar-questions">
        <div class="arrow-back-text" @click="goBack()">
          <img src="@/assets/icon-back.svg" class="arrow-icon" alt="back"/>
          <span class="back">{{ t('Back') }}</span>
        </div>
        <div class="progress-line">
          <div v-for="index in this.pageCount" :key="'P'+index">
            <img v-if="index === activeSection" src="@/assets/line-active.svg" alt="line"/>
            <img v-else src="@/assets/line-grey.svg" alt="line"/>
          </div>
        </div>
        <div class="menu-desktop"></div>
      </div>
    </div>
    <div class="container" v-if="questionsPage" style="min-height: 100vh">
      <div class="row">
        <div class="col">
          <div class="section">
            <div v-for="(quest, index) in questions" :key="'Q'+index">
              <div v-for="(category, indexCategory) in categories" :key="indexCategory">
                <h1 v-if="quest.page === activeSection && quest.order === 1
                 && quest.category === category.id" class="title-categories">{{ checkLanguage(category.name) }}</h1>
              </div>
            </div>
            <div v-for="(item, index) in questionsPage" :key="'S'+index">
              <div v-if="item.page === activeSection">
                <h3>{{ checkIndex(index) + " — " + checkLanguage(item.title) }}</h3>
                <div :class="item.answers.length > 3 ? 'question-ans' : 'question-ans-col'">
                  <div class="ans" v-for="(ans, secIndex) in item.answers" v-bind:key="'A'+secIndex"
                       :style="item.answers.length > 3  ? 'width: 47%' : '' " @click="goto('ans-'+index)">
                    <div class="answer grey"
                         v-if="controlAnswer(ans.title) !== false && !getActiveAnswer(item.id, ans.id, index)"
                         @click="setActiveAnswer(item.id, ans.id, valueText= $event.target.value, item.type, ans.title)">
                      {{ checkLanguage(ans.title) }}
                    </div>
                    <div
                        :style="ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD  ? 'padding:2px !important;'  : ''"
                        :class="(ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD) && item.answers.length > 3  ? 'container-text-field-row': 'container-text-field-col'"
                        class="answer active"
                        v-if="controlAnswer(ans.title) !== false && getActiveAnswer(item.id, ans.id, index)">
                      <span class="span-text" :class="item.answers.length < 3 ? 'align-center': ''"
                            v-if="ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD">{{
                          checkLanguage(ans.title)
                        }}
                      </span>
                      <div v-if="ans.type !== Type.TEXT_FIELD && item.type !== Type.TEXT_FIELD">
                        <span>{{ checkLanguage(ans.title) }}</span></div>
                      <div v-if="ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD"
                           :class="(ans.type === Type.TEXT_FIELD || item.type === Type.TEXT_FIELD) && item.answers.length < 3  ? 'text-field-col' : 'text-field-row'">
                        <div class="box-tf">
                          <img src="@/assets/pen.svg" class="pen-icon" alt="pen"/>
                          <input
                              :ref="'ans-'+index"
                              @change="setActiveAnswer(item.id, ans.id, valueText = $event.target.value, item.type, ans.title)"
                              type="text"
                              :value="getValue(item.id, ans.id) === undefined ? setValue(item.id, ans.id) : getValue(item.id, ans.id)"
                              :placeholder="label.textHere"
                              class="form-control-plaintext"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vh-100" v-else>
      <no-services-available></no-services-available>
    </div>
    <div class="continue" :class="questionsPage.length < 3 ? 'fixed-bottom':''"
         :style="isActive ? 'background: linear-gradient(57.64deg, #FFFFFF 11.51%, #FDB827 88.91%)' : 'background: #767676'"
         @click="nextSection(false)" v-if="activeSection < this.pageCount">{{ t('Continue') }}
    </div>
    <div class="continue fixed-bottom" @click="nextSection(true)" :class="questionsPage.length < 3 ? 'fixed-bottom':''"
         :style="isActive ? 'background: linear-gradient(57.64deg, #FFFFFF 11.51%, #FDB827 88.91%)' : 'background: #767676'"
         v-else>{{ t('Complete') }}
    </div>
    <modal-alert ref="modalAlert" :message="message"></modal-alert>
    <modal-confirm ref="modalConfirm"  message="Congratulazioni riceverai via mail la valutazione della tua azienda e la password per accedere all'area riservata" :click-send="sendFormResults"></modal-confirm>
    <loader v-if="this.loader" object="#f7b847" color1="#ffffff" color2="#17fd3d" size="6" speed="2" bg="#343a40"
            objectbg="#999793" opacity="10" :disableScrolling="false" name="dots"></loader>
  </div>
</template>
<script>
import $ from "jquery";
import EnumType from '../utils/EnumType';
import modalAlert from '../components/modalAlert';
import NoServicesAvailable from "../components/NoServicesAvailable";
import ModalConfirm from "../components/modalConfirm.vue";

export default {
  name: 'Form',
  components: {ModalConfirm, NoServicesAvailable, modalAlert},
  data: function () {
    return {
      payload: '',
      questions: [],
      categories: [],
      questionsPage: [],
      valueTextInput: [],
      selectedAnswer: [],
      valuesTextFields: [],
      answerTextFieldTypeId: [],
      pageCount: null,
      activeSection: null,
      loader: false,
      isActive: false,
      isAnswerActive: false,
      message: '',
      valueText: '',
      label: {
        textHere: ''
      },
      isSend: false,
      Type: {
        TEXT_FIELD: EnumType.TEXT_FIELD,
        SINGLE_CHOICE_OR_TEXT_FIELD: EnumType.SINGLE_CHOICE_OR_TEXT_FIELD,
        SINGLE_CHOICE: EnumType.SINGLE_CHOICE,

      },
      resultTot: {
        questionnaireId: '',
        responses: []
      },
    }
  },
  created() {
    let self = this;
    self.loader = true;
    if (self.$store.state.languageActive === "IT") {
      self.$translate.setLang('italian');
      self.message = 'Per continuare è necessario rispondere a tutte le domande';
      self.label.textHere = 'Scrivi qui';
    } else {
      self.$translate.setLang('english');
      self.message = 'To continue you have to answer all questions';
      self.label.textHere = 'Your text here';
    }
  },
  mounted() {
    let self = this;
    self.getQuestionnaire();
    if (self.selectedAnswer == null) {
      self.selectedAnswer = [];
    }
  },
  watch: {
    selectedAnswer: function (e) {
      if (e.length !== 0 && this.questionsPage.length !== 0) {
        if (e.length === this.questionsPage.length) {
          this.isActive = true;
        }
      } else {
        this.isActive = false;
      }
    },
    activeSection: function (newState, oldState) {
      let self = this;
      if (self.selectedAnswer.length !== 0) {
        self.isActive = true;
        for (const el of self.selectedAnswer) {
          if (el.text !== "") {
            self.valuesTextFields.push({
              questionId: el.questionId,
              answerId: el.answerId,
              text: el.text,
            })
          }
        }
      }
      self.questionsPage = [];
      if (newState !== oldState) {
        for (const question of self.questions) {

          if (question.page === newState) {
            self.questionsPage.push(question);
          }
        }
        self.questionsPage.sort((a, b) => a.order > b.order ? 1 : -1);
      }
    }
  },
  methods: {
    goto(refName) {
      let self = this;
      const element = self.$refs[refName];
      if (element) {
        element[0].focus();
        const top = element[0].offsetTop;
        window.scrollTo(0, top - 40);
      }
    },
    getValue(itemId, answerId) {
      let self = this;
      for (let i = 0; i < self.valuesTextFields.length; i++) {
        if (self.valuesTextFields[i].questionId === itemId && self.valuesTextFields[i].answerId === answerId) {
          return self.valuesTextFields[i].text;
        }
      }
    },
    sendFormResults() {
      this.postQuestionnaire(this.payload);

    },
    setValue(itemId, answerId) {
      let self = this;
      for (let i = 0; i < self.selectedAnswer.length; i++) {
        if (self.selectedAnswer[i].questionId === itemId && self.selectedAnswer[i].answerId === answerId) {
          return self.selectedAnswer[i].text;
        }
      }
    },
    async getQuestionnaire() {
      let self = this;
      await self.$store.dispatch("getQuestionnaireAction").then(() => {
        self.loader = false;
        self.questions = self.$store.state.questionsList;
        self.categories = self.$store.state.categoriesList;
      })
      self.$store.dispatch("numbersPagesAction").then(el => {
        self.pageCount = el;
      });
      if (self.activeSection == null) {
        self.activeSection = 1;
      }
    },
    controlAnswer(title) {
      if (this.$store.state.languageActive === 'EN') {
        return !!title.en;
      } else {
        return !!title.it;
      }
    },
    nextSection(isFinished) {
      let self = this;
      if (!isFinished) {
        let contQ = 0;
        let contSelected = 0;
        if (self.selectedAnswer != null) {
          for (const question of self.questions) {
            for (const cat of self.categories) {
              if (cat.id === question.category && question.page === self.activeSection) {
                contQ++;
              }
            }
          }
          // eslint-disable-next-line no-unused-vars
          for (const ansSelected of self.selectedAnswer) {
            contSelected++;
          }
          let isTextFieldEmpty = self.textFieldControl();
          if (contSelected !== contQ || isTextFieldEmpty) {
            this.$refs.modalAlert.showModal();
          } else {
            if (self.$store.state.savedAnswers[self.activeSection - 1] !== undefined) {
              self.$store.state.savedAnswers[self.activeSection - 1] = self.selectedAnswer;
            } else {
              self.$store.state.savedAnswers.push(self.selectedAnswer);
            }
            $('html, body').animate({scrollTop: 0}, 300);
            self.activeSection++;
            if (self.$store.state.savedAnswers[self.activeSection - 1] === undefined) {
              self.selectedAnswer = [];
            } else {
              self.selectedAnswer = self.$store.state.savedAnswers[this.activeSection - 1];
            }
            self.questionsPage = [];
          }
        }

      } else {

        let contQ = 0;
        let contSelected = 0;
        if (self.selectedAnswer != null) {
          for (const question of self.questions) {
            for (const cat of self.categories) {
              if (cat.id === question.category && question.page === self.activeSection) {
                contQ++;
              }
            }
          }
          // eslint-disable-next-line no-unused-vars
          for (const ansSelected of self.selectedAnswer) {
            contSelected++;
          }
        }
        let isTextFieldEmpty = self.textFieldControl();
        if (contSelected !== contQ || isTextFieldEmpty) {
          this.$refs.modalAlert.showModal();
        } else {
          self.$store.state.savedAnswers.push(self.selectedAnswer);
          for (let i = 0; i < this.$store.state.savedAnswers.length; i++) {
            const result = this.$store.state.savedAnswers[i].map((answer) => {
              return {
                questionId: answer.questionId,
                answerId: answer.answerId,
                text: answer.text,
                type: answer.type
              }
            });
            for (const resultKey of result) {
              console.log(resultKey)
              if (resultKey.answerId === "A22.1" && resultKey.questionId === "Q22") {
                console.log(self.$store.state.nameUserGuest);
                self.$store.state.nameUserGuest = this.firstUppercase(resultKey.text);
              }
              if (resultKey.answerId === "A23.1" && resultKey.questionId === "Q23") {
                console.log(self.$store.state.surnameUserGuest);
                self.$store.state.surnameUserGuest = this.firstUppercase(resultKey.text);
              }
              if (resultKey.questionId === "Q1") {
                console.log(self.$store.state.guestHub);
                self.$store.state.guestHub = resultKey.text;
              }
            }
            self.resultTot.responses = self.resultTot.responses.concat(result);
          }
          self.resultTot.questionnaireId = self.$store.state.questionnaireId;
          const json = JSON.stringify(self.resultTot);
          const router = self.$router;
          self.payload = {
            json: json,
            router: router
          }
          self.$store.state.savedAnswers = [];
          console.log(self.payload);
          this.$refs.modalConfirm.showModal();
        }
      }
    },
    async postQuestionnaire(payload) {
      let self = this;
      self.loader = true;
      await self.$store.dispatch('postQuestionnaireAction', payload).then(() => {
        self.loader = false;
      });

      this.$refs.modalConfirm.hideModal();
      await self.$store.dispatch('getPartnersAction');
    },
    textFieldControl() {
      let self = this;
      self.answerTextFieldTypeId = [];
      for (const question of self.questions) {
        for (const answersElement of question.answers) {
          if (question.type === self.Type.TEXT_FIELD || answersElement.type === self.Type.TEXT_FIELD) {
            self.answerTextFieldTypeId.push(answersElement.id);
          }
        }
      }
      for (const selectedAnswerElement of self.selectedAnswer) {
        for (let i = 0; i < this.answerTextFieldTypeId.length; i++) {
          if (selectedAnswerElement.answerId === self.answerTextFieldTypeId[i]) {
            if (selectedAnswerElement.text === undefined) {
              return true;
            } else if (selectedAnswerElement.text === '') {
              return selectedAnswerElement.text === ''
            }
          }
        }
      }
    },
    getActiveAnswer(questionId, answerId) {
      let temp = false;
      for (const el of this.selectedAnswer) {
        if (el.questionId === questionId) {
          temp = el.answerId === answerId;
        }
      }
      return temp;
    },
    setActiveAnswer(questionId, answerId, text, answerType, ansTitle) {
      let self = this;
      if (self.selectedAnswer.length === 0) {
        self.selectedAnswer.push({
          questionId: questionId,
          answerId: answerId,
          text: questionId !== 'Q1' || questionId === 'Q1' && answerId === 'A1.1' ? text : self.checkLanguage(ansTitle),
          type: answerType,
        });
      } else {
        let index = 0;
        let exist = false;
        for (const el of self.selectedAnswer) {
          if (el.questionId === questionId) {
            exist = true;
            let temp = self.selectedAnswer;
            self.selectedAnswer = [];
            temp[index] = {
              questionId: questionId,
              answerId: answerId,
              text: questionId !== 'Q1' && answerId !== 'A1.1' || questionId === 'Q1' && answerId === 'A1.1' ? text : self.checkLanguage(ansTitle),
              type: answerType,
            };
            self.selectedAnswer = temp;
          }
          index++;
        }
        if (!exist)
          self.selectedAnswer.push({
            questionId: questionId,
            answerId: answerId,
            text: questionId !== 'Q1' || questionId === 'Q1' && answerId === 'A1.1' ? '' : self.checkLanguage(ansTitle),
            type: answerType,
          });
      }
    },
    checkIndex(index) {
      index++;
      if (index < 9) return "0" + index;
      return index;
    },
    checkLanguage(title) {
      if (this.$store.state.languageActive === 'EN') {
        return title.en;
      } else {
        return title.it;
      }
    },
    goBack() {
      if (this.activeSection === 1 || this.activeSection === null) {
        this.$store.state.savedAnswers = [];
        this.$router.back();
      } else {
        this.activeSection--;
        this.selectedAnswer = this.$store.state.savedAnswers[this.activeSection - 1];
      }
    },
    firstUppercase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },

  }
}
</script>

<style scoped>
h1 {
  font-family: "Sora", sans-serif;
  font-size: 40px;
  line-height: 1.2;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -1px;
  text-align: left;
  background: #FDB827;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h3 {
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  color: #FFFFFF;
  letter-spacing: -1px;
  text-align: left;
}

input:focus {
  outline: none !important;
}

.form-control-plaintext {
  color: white;
}


/*

@media (max-width: 30em) {
  .form-control-plaintext {
    padding: 0px;
    margin-left: 5px;
  }

  ::placeholder {
    font-size: 12px;
  }
}

@media (max-width: 25em) {
  .form-control-plaintext {
    padding: 0px;
    margin-left: 5px;
  }

  ::placeholder {
    font-size: 9px;
  }

}*/

</style>
