<template>
  <div class="text-center">
    <b-button type="button" id="show-btn" v-bind:style="'width:'+widthBotton " @click="showModal"
              class=" btn btn-warning p-3 font-weight-bolder">
      {{ textBotton }}
    </b-button>

    <b-modal ref="my-modal" hide-footer hide-header>
      <div class="flex-wrap d-flex modal-respons">
        <div style="text-align: end; margin: 10px" class="col-12">
          <button @click="closeModal" class="fa fa-times mr-4" style="color: #FFFFFF; align-self: end"
                  aria-hidden="true"></button>
        </div>
        <div class="subscribe-container col-lg-6">
          <h3 class="subscribe-text ">{{ t('Why subscribe?') }}</h3>
          <ul class="mt-30">
            <li class="icon-list text-white"><b>{{
              t('Discover')
            }}</b><br>{{ t('Ready to start? Check out your profile and StarFounder page') }}
            </li>
            <li class="icon-list text-white mt-3">
              <b>{{ t('Explore') }}</b><br>{{ t('Explore our network. We are stronger together!') }}
            </li>
            <li class="icon-list text-white mt-3">
              <b>{{ t('Get inspired') }}</b><br>{{
                t('Learn about and be inspired by the best startups in your sector')
              }}
            </li>
            <li class="icon-list text-white mt-3">
              <b>{{ t('Promote') }}</b><br>{{ t('Share your "one minute pitch" and build your network') }}
            </li>
          </ul>
        </div>
        <div class="form-container col-lg-6">
          <h2 style="margin-top: 5px" class="text-white">{{ t('Sign up') }}</h2>

          <div :style="errorField.name ? ' border: 1px solid red;'  : ''" class="tf-auth" style="margin-top: 30px;">
            <img src="../assets/modalSignUp/user.svg" alt="name" class="mr-1 ml-1">
            <input class="input-box" type="text" :placeholder="label.name" v-model.trim="name">
          </div>

          <div :style="errorField.surname ? ' border: 1px solid red;' : '' " class="tf-auth">
            <img src="../assets/modalSignUp/user.svg" alt="name" class="mr-1 ml-1">
            <input type="text" :placeholder="label.surname" v-model.trim="surname" class="input-box">
          </div>

          <div :style="errorField.email || errorField.emailValid ? ' border: 1px solid red;' : '' " class="tf-auth">
            <img src="../assets/modalSignUp/mailbox.svg" alt="name" class="mr-1 ml-1">
            <input type="email" placeholder="email" v-model.trim="email" class="input-box">
          </div>

          <div class="tf-auth" :style="errorField.confirmPassword ? ' border: 1px solid red;' : ''">
            <img src="../assets/modalSignUp/password.svg" alt="name" class="mr-1 ml-1">
            <input :type="this.typePassword" placeholder="password" v-model.trim="password" class="input-password">
            <img class="eye-password" @click="showPassword()" :src="insertImg(typePassword)" alt="eye">

          </div>

          <div class="tf-auth" :style="errorField.confirmPassword ? ' border: 1px solid red;' : ''">
            <img src="../assets/modalSignUp/password.svg" alt="name" class="mr-1 ml-1">

            <input :type="this.typeConfirmPassword" class="input-password" :placeholder="label.confirmPassword"
                   v-model.trim="confirmPassword">
            <img class="eye-password" alt="eye" @click="showPasswordConfirm()" :src="insertImg(typeConfirmPassword)">

          </div>
          <p style="color: red; margin-top: 16px; font-size: 12px" class="text-right" v-if="modalMessage"
             v-html="modalMessage">
          </p>
          <p style=" font-size: 13px;" class="mt-3"><input type="checkbox" :value="termsCoditions"
                                                           @click="acceptTermCondition()" class="m-1 "/>{{
                                                             t('By creating an account, you agree to our')
                                                           }} <a href="https://www.starfounder.it/pdf/20211020_Termini%20e%20Condizioni.pdf" target="_blank"
                  style="color: #519AC5">{{ t('Terms & Conditions') }}</a></p>
          <button @click="registration()" class="btn-signup">{{ t('Sign up') }}</button>
        </div>
      </div>
    </b-modal>
    <Modal-alert ref="modalAlert" :message="message"></Modal-alert>
    <loader v-if="this.loader" object="#f7b847" color1="#ffffff" color2="#17fd3d" size="6" speed="2" bg="#343a40"
            objectbg="#999793" opacity="80" :disableScrolling="false" name="dots"></loader>

  </div>


</template>

<script>
import ModalAlert from "./modalAlert";

export default {
  name: "ModalSignUp",
  components: {ModalAlert},
  props: {
    textBotton: String,
    widthBotton: String,
  },
  data: function () {
    return {
      loader: false,
      termsCoditions: false,
      message: "",
      email: "",
      name: this.$store.state.nameUserGuest,
      surname: this.$store.state.surnameUserGuest,
      typePassword: "password",
      typeConfirmPassword: "password",
      password: "",
      modalMessage: "",
      confirmPassword: "",
      errorField: {
        name: false,
        email: false,
        surname: false,
        password: false,
        passwordLenght: false,
        confirmPassword: false,
        emailValid: false,
      },
      label: {
        name: "",
        surname: "",
        confirmPassword: "",
      }
    }
  },
  created() {
    let self = this;
    if (self.$store.state.languageActive === "IT") {
      self.$translate.setLang('italian');
      self.label.name = 'nome';
      self.label.surname = 'cognome';
      self.label.confirmPassword = 'conferma password';
    } else {
      self.$translate.setLang('english');
      self.label.name = 'name';
      self.label.surname = 'surname';
      self.label.confirmPassword = 'confirm password';
    }
  },
  watch: {
    name: function (e) {
      if (e.length !== 0) {
        this.errorField.name = false;
      }
    },
    surname: function (e) {
      if (e.length !== 0) {
        this.errorField.surname = false;
      }
    },
    email: function (e) {
      if (e.includes('@')) {
        this.errorField.email = false;
        this.errorField.emailValid = false;
      }
    },
    password: function (e) {
      if (e.length > 8) {
        this.errorField.password = false;
        this.errorField.passwordLenght = false;
        this.loader = false;

      }
    },

    confirmPassword: function (e) {
      if (e.length !== 0) {
        this.errorField.confirmPassword = false;
        this.loader = false;
      }
    },

  },
  methods: {
    acceptTermCondition() {
      let self = this;
      self.termsCoditions = !self.termsCoditions;
    },
    showPassword() {
      if (this.typePassword === 'password') {
        this.typePassword = 'text'
      } else {
        this.typePassword = 'password'
      }
    },
    insertImg(pwd) {
      if (pwd === 'password') {
        return require('../assets/eye-slash.svg');
      } else {
        return require('../assets/eye.svg');
      }
    },
    showPasswordConfirm() {
      if (this.typeConfirmPassword === 'password') {
        this.typeConfirmPassword = 'text';
      } else {
        this.typeConfirmPassword = 'password';
      }
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    closeModal() {
      this.$refs['my-modal'].hide();
    },
    showError() {
      let self = this;
      if (self.email === "") {
        self.errorField.email = true;
      }
      if (self.surname === "") {
        self.errorField.surname = true;
      }
      if (self.name === "") {
        self.errorField.name = true;
      }
      if (self.password === "") {
        self.errorField.password = true;
      }
      if (self.email === "" || self.surname === "" || self.name === "" || self.password === "") {
        self.modalMessage = "*è necessario compilare tutti i campi <br>";
        self.loader = false;
      }
      if (!self.email.includes('@')) {
        self.errorField.emailValid = true;
        self.modalMessage = self.modalMessage + " **email inserita non risulta valida <br>";
        self.loader = false;
      }
      if (self.password.length < 8) {
        self.errorField.passwordLenght = true;
        self.modalMessage = self.modalMessage + " ***la password deve essere lunga almeno 8 caratteri <br>";
        self.loader = false;
      }
      if (self.confirmPassword === "" || self.password !== self.confirmPassword) {
        self.errorField.confirmPassword = true;
        self.modalMessage = self.modalMessage + "****le password non coincidono  <br>";
        self.loader = false;
      }
      if (self.termsCoditions === false) {
        self.modalMessage = "accettare term & condition";
        self.loader = false;
      }
    },

    registration() {
      let self = this;
      self.loader = true;
      self.modalMessage = "";
      self.showError();
      if (self.modalMessage === "") {
        self.modalMessage = "";
        let payload = {
          name: self.name,
          surname: self.surname,
          email: self.email,
          password: self.password,
          router: self.$router
        }
        self.$store.dispatch("registerAction", payload).then(() => {
          self.loader = false;
        })
        if (self.$store.state.error) {
          self.loader = false;
          self.message = self.$store.state.responseDataMessage;
          self.$refs.modalAlert.showModal();
          self.$store.state.responseDataErrorCode = "";
          self.$store.state.error = false ;
        }
      }
    }
  }
}
</script>

<style scoped>

h3 {
  text-align: left;
}

ul.mt-30 {
  margin-top: 30px;
}



</style>