<template>
  <div class="text-center">
    <b-button type="button" id="show-btn" v-bind:style="'width:'+widthBotton " @click="showModal"
              class=" btn btn-warning px-3 py-2 font-weight-bolder">
      {{ textBotton }}
    </b-button>

    <b-modal body-class="modal-sign-in" style="position: absolute;" ref="my-modal" hide-footer hide-header>
      <div class="flex-wrap modal-sign-in">

        <div class="form-container col-lg-12">
          <h2 style="margin-top: 5px" class="text-white">{{ t('Sign In') }}</h2>

          <div :style="errorField.email || errorField.emailValid ? ' border: 1px solid red;' : '' " class="tf-auth">
            <img src="../assets/modalSignUp/mailbox.svg" alt="name" class="mr-1 ml-1">
            <input type="email" placeholder="email" v-model.trim="email" class="input-box">
          </div>

          <div class="tf-auth" :style="errorField.confirmPassword ? ' border: 1px solid red;' : ''">
            <img src="../assets/modalSignUp/password.svg" alt="name" class="mr-1 ml-1">
            <input :type="this.typePassword" placeholder="password" v-model.trim="password" class="input-password">
            <img class="eye-password" @click="showPassword()" :src="insertImg(typePassword)" alt="eye">

          </div>

          <p style="color: red; margin-top: 16px; font-size: 12px" class="text-right" v-if="modalMessage"
             v-html="modalMessage">
          </p>
          <p style=" font-size: 13px;" class="mt-3">{{ t('Don\'t have an account?') }}
            <button style="color: #519AC5; cursor: pointer;" @click="startForm()">{{ t('Click here') }}</button>
            {{ t('to start our questionnaire') }}
          </p>
          <button @click="login()" class="btn-signup">{{ t('Sign In') }}</button>
        </div>
      </div>
    </b-modal>
    <modal-alert ref="modalAlert" :message="message"></modal-alert>
    <loader style="z-index: 9999999" v-if="this.loader" object="#f7b847" color1="#ffffff" color2="#17fd3d" size="6"
            speed="2" bg="#343a40"
            objectbg="#999793" opacity="80" :disableScrolling="false" name="dots"></loader>
  </div>


</template>

<script>
import ModalAlert from "./modalAlert";
import $ from "jquery";

export default {
  name: "ModalSignIn",
  components: {ModalAlert},
  props: {
    textBotton: String,
    widthBotton: String,
  },
  data: function () {
    return {
      loader: false,
      message: "",
      email: "",
      name: "",
      surname: "",
      typePassword: "password",
      typeConfirmPassword: "password",
      password: "",
      modalMessage: "",
      confirmPassword: "",
      errorField: {
        name: false,
        email: false,
        surname: false,
        password: false,
        passwordLenght: false,
        confirmPassword: false,
        emailValid: false,
      },
      label: {
        name: "",
        surname: "",
        confirmPassword: "",
      }
    }
  },
  created() {
    let self = this;
    if (self.$store.state.languageActive === "IT") {
      self.$translate.setLang('italian');
      self.label.name = 'nome';
      self.label.surname = 'cognome';
      self.label.confirmPassword = 'conferma password';
    } else {
      self.$translate.setLang('english');
      self.label.name = 'name';
      self.label.surname = 'surname';
      self.label.confirmPassword = 'confirm password';
    }
  },
  watch: {
    name: function (e) {
      if (e.length !== 0) {
        this.errorField.name = false;
      }
    },
    surname: function (e) {
      if (e.length !== 0) {
        this.errorField.surname = false;
      }
    },
    email: function (e) {
      if (e.includes('@')) {
        this.errorField.email = false;
        this.errorField.emailValid = false;
      }
    },
    password: function (e) {
      if (e.length > 8) {
        this.errorField.password = false;
        this.errorField.passwordLenght = false;
        this.loader = false;
      }
    },
    confirmPassword: function (e) {
      if (e.length !== 0) {
        this.errorField.confirmPassword = false;
        this.loader = false;
      }
    },

  },
  methods: {
    showPassword() {
      if (this.typePassword === 'password') {
        this.typePassword = 'text'
      } else {
        this.typePassword = 'password'
      }
    },
    startForm() {
      $('html, body').animate({scrollTop: 0}, 300);
      this.$router.push('/form');
    },
    insertImg(pwd) {
      if (pwd === 'password') {
        return require('../assets/eye-slash.svg');
      } else {
        return require('../assets/eye.svg');
      }
    },
    showPasswordConfirm() {
      if (this.typeConfirmPassword === 'password') {
        this.typeConfirmPassword = 'text';
      } else {
        this.typeConfirmPassword = 'password';
      }
    },
    showModal() {
      this.$refs['my-modal'].show();
      this.$emit('closeMenu');
    },
    showError() {
      let self = this;
      if (self.email === "") {
        self.errorField.email = true;
      }
      if (self.password === "") {
        self.errorField.password = true;
      }
      if (self.email === "" || self.password === "") {
        self.modalMessage = "*è necessario compilare tutti i campi <br>";
        self.loader = false;
      }
      if (!self.email.includes('@')) {
        self.errorField.emailValid = true;
        self.modalMessage = self.modalMessage + " **email inserita non risulta valida <br>";
        self.loader = false;
      }
      if (self.password.length < 8) {
        self.errorField.passwordLenght = true;
        self.modalMessage = self.modalMessage + " ***la password deve essere lunga almeno 8 caratteri <br>";
        self.loader = false;
      }

    },
    login() {
      let self = this;
      self.loader = true;
      self.modalMessage = "";
      self.showError();
      if (self.modalMessage === "") {
        self.modalMessage = "";
        let payload = {
          email: self.email,
          password: self.password,
          router: self.$router
        }
        self.$store.dispatch("loginAction", payload).then(() => {
          self.loader = false;
          if (!self.$store.state.error) {
            self.computedQuestionnaire();
          }
          if (self.$store.state.error) {
            self.message = self.$store.state.responseDataMessage;
            self.$refs.modalAlert.showModal();
            self.$store.state.responseDataErrorCode = "";
            self.$store.state.error = false;

          }
        })
      }
    },
    computedQuestionnaire() {
      let self = this;
      self.$store.dispatch('getComputedQuestionnaire', self.$router).then(() => {
        self.loader = false;
      })
    }
  }
}
</script>

<style scoped>

h3 {
  text-align: left;
}

ul.mt-30 {
  margin-top: 30px;
}


</style>