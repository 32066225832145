import Vue from 'vue'
import VueRouter from 'vue-router'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueScrollTo from 'vue-scrollto';
import Translate from './services/translate'
import {store} from './store/store';
import VModal from "vue-js-modal";


require('@/assets/style.css')

// Import pages
import App from './App.vue'
import Form from './pages/Form'
import Home from './pages/Home'
import Dashboard from './pages/Dashboard'
import DashboardGuest from "./pages/DashboardGuest";
import ExtraForm from "./pages/ExtraForm";
import loader from "vue-ui-preloader";
import VueEllipseProgress from 'vue-ellipse-progress';

console.log(Translate)

Vue.use(VueRouter)
Vue.use(VueScrollTo)
Vue.use(BootstrapVue)
Vue.use(VueEllipseProgress)
Vue.use(IconsPlugin)
Vue.use(loader)
Vue.use(VModal)
Vue.config.productionTip = false;


Vue.config.productionTip = false

let router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {path: '/', component: Home},
        {path: '/form', component: Form},
        {path: '/dashboard', component: Dashboard},
        {path: '/dashboardGuest', component: DashboardGuest},
        {path: '/extraForm', component: ExtraForm}
    ]
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
