<template>
  <modal title="error" name="modal"
         :adaptive="true">
    <div @click="hideModal" class="pop-up-container">
      <div class="text-center my-4">
        <img src="@/assets/congratulationsSendForm.png" class="img-custom"/>
      </div>
      <p class="text-center text-modal">
        {{ message !== "" ? message : $store.state.responseDataMessage }}</p>

      <div class="pop-up" @click="clickSend()"><p class="pop-up-close">OK</p></div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "modalConfirm",
  props: {message: String, clickSend: Function},
  methods: {
    showModal() {
      this.$modal.show('modal');
      console.log("test");
    },
    hideModal() {
      this.$modal.hide('modal');
    },
  },
}
</script>

<style scoped>
@media screen and (min-width: 75em) {
  .intro-container p {
    font-size: 16px;
  }
}

.intro-container p {
  margin-top: 0;
}

.img-custom {
  width: 15%;
}
</style>