<template>
  <div>
    <header>
      <div class="top-nav">
        <div class="top-container ">
          <img @click="goToHome" src="@/assets/Vector.png" style="cursor: pointer" class="bf-icon"/>
          <div class="row align-items-center">
            <modal-sign-in v-bind:text-botton="t('Sign In')"></modal-sign-in>
          </div>
        </div>
      </div>
    </header>
    <main>
      <h1 class="text-center congrats-text">{{ t('Congrats ') }} {{ this.$store.state.nameUserGuest + '!' }}</h1>

      <section class="intro-container row justify-content-center pb-4 pt-5">
        <div class="col">
          <div class="row justify-content-center mb-5">
            <div class="container text-white position-absolute" style="top: -50px">
              <div class="circTxt" id="test"></div>
            </div>
            <vue-ellipse-progress
                :progress="this.finalUserType()"
                :angle="-90"
                :color="gradient"
                :legend="false"
                dash="strict 5 0.98"
                :emptyColor="emptyColor"
                :emptyColorFill="emptyColorFill"
                :size="300"
                thickness="2%"
                emptyThickness="5%"
                animation="1500 1500"
                line-mode="bottom 10"
                fontColor="white"
                fontSize="3rem">
              <p class="mb-0" style="text-align: center; font-size: 1rem;" slot="legend-caption">Your Stage:</p>
              <p class="mt-0" style="text-align: center; font-size: 3rem;" slot="legend-caption">
                {{ this.$store.state.elaborateGuestResponse.data.payload.userType }}</p>
            </vue-ellipse-progress>

          </div>
        </div>
        <div class="col-sm-6 col-md-7 mb-3 align-self-center ">
          <h1 style="font-size: 25px" id="prova1">
            {{ t('Thanks ') }}
            {{ this.$store.state.nameUserGuest }}
            {{ t('We carried out a preliminary assessment of your idea.') }}
            <br>
            <br>
            {{ t('Register to discover your full profile and post it in the common area.') }}
          </h1>
          <br>
          <modal-sign-up class="pt-2" width-botton="100%" :text-botton="t('Sign up')"></modal-sign-up>
        </div>
      </section>


      <section class="intro-container" style="text-align: start">
        <b-tabs class="tab-nav-dashboard nav-tab-a text-white-50 " content-class="mt-3"
                active-nav-item-class="text_dark" style="font-size: 18px; ">

          <b-tab class="text-white" :title="t('Partners')">
            <b-card class="bcard-container">
              <div class="d-flex flex-wrap row-cols-sm-1 row-cols-md-2 card-deck text-light"
                   v-if="this.$store.state.elaborateGuestResponse.data.payload.partners?.length > 0">
                <CardEcosystem v-for="(item, key) in this.$store.state.elaborateGuestResponse.partners" :key="key"
                               v-bind:category="item.category"
                               v-bind:email="item.city"
                               v-bind:name="item.name"
                               v-bind:website="item.link"/>
              </div>
              <div v-else>
                <p class="mt-0" style="font-size: 25px;text-align: center">
                  {{
                    t('Within a few days there will be an opportunity to connect with partners in the area who can help you develop your idea.')
                  }}
                </p>
              </div>
            </b-card>
          </b-tab>
          <b-tab class="text-white" title="1 Minute Pitch">
            <b-card class="bcard-container">
              <p class="mt-0" style="font-size: 25px;text-align: center">
                {{ t('Thanks to your answers we have generated the 1 Minute Pitch of') }}
                {{ this.$store.state.elaborateGuestResponse.data.payload.name }}.</p>
              <h1 style="font-size: 30px;text-align: center">{{ t('Sign up for more details') }}</h1>
            </b-card>
          </b-tab>
        </b-tabs>
        <modal-sign-up class="m-3" v-bind:text-botton="t('DISCOVER ALL PARTNERS')"></modal-sign-up>

      </section>


    </main>
    <footer style="background-color: black; height: 50px">
      <div class="intro-container">
        <img src="@/assets/BF-brand-grey.svg">
      </div>
    </footer>
  </div>
</template>

<script>
import CardEcosystem from "../components/CardEcosystem";
import ModalSignUp from "../components/ModalSignUp";
import ModalSignIn from "../components/ModalSignIn";

export default {
  name: "DashboardGuest",
  components: {CardEcosystem, ModalSignUp, ModalSignIn},
  data: function () {
    return {
      myJsonPayload: [],
      cardList: [],
      responseElaborate: null,
      gradient: {
        radial: false,
        colors: [
          {
            color: '#fff',
            offset: 0,
            opacity: '1',
          },
          {
            color: '#FDB827',
            offset: 100,
            opacity: '1',
          },
        ]
      },
      emptyColor: {
        radial: false,
        colors: [
          {
            color: '#FDB827',
            offset: 1,
            opacity: '0.2',
          },
          {
            color: '#FDB827',
            offset: 20,
            opacity: '0.4',
          },
          {
            color: '#FDB827',
            offset: 40,
            opacity: '0.6',
          }, {
            color: '#FDB827',
            offset: 80,
            opacity: '0.8',
          }, {
            color: '#FDB827',
            offset: 100,
            opacity: '1',
          },
        ]
      },
      emptyColorFill: {
        radial: true,
        colors: [
          {
            color: "#FDB827",
            offset: "50",
            opacity: "0.2",
          },
          {
            color: "#FDB827",
            offset: "50",
            opacity: "0.15",
          },
          {
            color: "#FDB827",
            offset: "70",
            opacity: "0.15",
          },
          {
            color: "#FDB827",
            offset: "70",
            opacity: "0.1",
          },
          {
            color: "#FDB827",
            offset: "90",
            opacity: "0.1",
          },
          {
            color: "transparent",
            offset: "90",
            opacity: "0.1",
          },
          {
            color: "transparent",
            offset: "95",
            opacity: "0.1",
          },
          {
            color: "transparent",
            offset: "95",
            opacity: "0.1",
          },
        ],
      },
    }
  },
  mounted() {
    this.circularText(" Newbie  Low  Medium  High   Top ", 170, 0);
    if (this.$store.state.languageActive === "IT") {
      this.$translate.setLang('italian');
    } else {
      this.$translate.setLang('english');
    }
    if (this.myJsonPayload.payload !== undefined) {
      for (let i = 0; i < 8; i++) {
        this.myJsonPayload.payload[i].link = this.linkFormatter(this.myJsonPayload.payload[i].link);
        this.cardList.push(this.myJsonPayload.payload[i]);
      }
    }
  },
  methods: {
    circularText(txt, radius, classIndex) {
      txt = txt.split(""),
          classIndex = document.getElementsByClassName("circTxt")[classIndex];

      let deg = 360 / txt?.length,
          origin = 0;

      txt.forEach((ea) => {
        ea = `<p style='height:${radius}px;position:absolute;transform:rotate(${origin}deg);transform-origin:0 100%'>${ea}</p>`;
        classIndex.innerHTML += ea;
        origin += deg;
      });
    },
    linkFormatter(link) {
      window.scrollTo(0, 0);

      let newLink = link.replace(/(^\w+:|^)\/\//, '');
      let newLinkSearch = newLink.search("www");
      if (newLinkSearch === -1) {
        newLink = "www." + newLink;
      }
      return newLink;
    },
    finalUserType() {
      if (this.$store.state.elaborateGuestResponse.data.payload.userType === 'SEED') {
        return Math.round(this.$store.state.elaborateGuestResponse.data.payload.valueQuestionnaire.seed)
      } else if (this.$store.state.elaborateGuestResponse.data.payload.userType === 'FOUNDER') {
        return Math.round(this.$store.state.elaborateGuestResponse.data.payload.valueQuestionnaire.founder);
      } else if (this.$store.state.elaborateGuestResponse.data.payload.userType === 'GROWTH') {
        return this.$store.state.elaborateGuestResponse.data.payload.valueQuestionnaire.later
      } else {
        return 0
      }
    },
    checkLanguage(title) {
      if (this.$store.state.languageActive === 'EN') {
        return title.en;
      } else {
        return title.it;
      }
    },
    goToHome() {
      this.$router.push('/');
    },
  }
}
</script>

<style scoped>
body {
  background: #111;
}

.container {
  /*centers in the container*/
  text-align: center;
}

div.circTxt {
  /*allows for centering*/
  display: inline-block;
  /*adjust as needed*/
  margin-bottom: 128px;
  color: whitesmoke;
}


@media (max-width: 1200px) {

  .modal-content {
    margin-top: 285px;
  }
}
</style>