import Vue from 'vue'
import VueTranslate from 'vue-translate-plugin'

Vue.use(VueTranslate);

Vue.locales({
    italian: {
        'Test your idea': 'Valuta la tua idea',
        'How it works': 'Come funziona',
        'Network': 'L\'ecosistema',
        'Mission': 'La nostra mission',
        'Log In': 'Login',
        'Do you have a great idea?': 'Hai un\'idea da realizzare? Hai da poco lanciato la tua startup?',
        'Are you launching a Start-up?': 'Valutala con noi!',
        'Many ideas remain such for fear of failure, or because most of us don’t know how to launch and scale a startup': 'Spesso molte idee rimangono chiuse in un cassetto. Vogliamo aiutarti a valutare la tua idea perché possa realizzarsi.',
        'Your email here': 'La tua email',
        'Sign Up': 'Registrati',
        'How does StarFounder work?': 'Scopri come StarFounder può aiutarti',
        'Evaluate': 'Valuta la tua idea',
        'Identify the viability of your idea using our quick survey': 'Tramite il nostro test puoi sapere i punti di forza e di miglioramento della tua idea',
        'Discover': 'Scopri il tuo profilo',
        'Ready to start? Check out your profile and StarFounder page': 'Pronto a partire? Ti mostriamo tutto in un profilo, inclusi i next steps per avviare la tua idea.',
        'Explore': 'Esplora l\'ecosistema',
        'Explore our network. We are stronger together!': 'Scopri i player dell\'ecosistema che meglio si adattano alla tua idea. Insieme tutto è più semplice!',
        'Get inspired': 'Trova l\'ispirazione giusta',
        'Learn about and be inspired by the best startups in your sector': 'Approfondisci le più interessanti startups del tuo settore',
        'Promote': 'Promuovi la tua idea',
        'Share your "one minute pitch" and build your network': 'Condividi il tuo "one minute pitch" per iniziare a costruire il tuo network',
        'Next steps': 'E dopo?',
        'Keep in touch and stay updated on the Founder Tools we are launching shortly': 'Resta in contatto con noi. Presto nuovi strumenti digitali utili per la tua idea',
        'Hey there! We are ready to connect founders to a global network of over 1000 connections across 8 Hubs. What is an Hub? Is a local community of entrepreneurs, startups, accelerators, institutions, venture capitalist and more': 'Siamo pronti a collegare founders con grandi idee ad un ecosistema globale con più di 1000 players su 8 Hubs',
        'We work hard on a daily basis to grow our StarFounder Ecosystem Map': 'Lavoriamo costantemente per far crescere  la nostra StarFounder Ecosystem Map',
        'Are you interested in connecting with your local community taking it to the next level? StarFounder is hiring ambitious Ambassadors to help us achieve our goals': 'Sei interessato a guidare il tuo Hub locale? StarFounder cerca ambassadors che ci aiutino a raggiungere il nostro ambizioso obiettivo, venendo ricompensati per i loro sforzi.',
        'Become an ambassador': 'Diventa un Ambassador',
        'StarFounder Hub': 'StarFounder Hub',
        'Incubators and accelerators': 'Incubatori e acceleratori',
        'Investors': 'Investitori',
        'Startups': 'Startups',
        'Think Tank, Research Centers and University': 'Università e centri di ricerca',
        'Corporate': 'Aziende corporate',
        'Foster innovation and entrepreneurship globally using simplified digital tools.': 'Diffondere l\'imprenditorialità e l\'innovazione in maniera semplice e digitale',
        'StarFounder was created to generate a positive impact in society. We believe that supporting entrepreneurship is the enabling factor for achieving economic, social and sustainable development goals': 'StarFounder nasce per generare un impatto positivo nella società. Crediamo che supportare l’imprenditorialità sia il fattore abilitante per il raggiungimento degli obiettivi crescita economica, sociale e di sviluppo sostenibile',
        'Community': 'Community',
        'Free, Secure and Inclusive, StarFounder is the global community for those who want to build and grow their business idea': 'Libera, Aperta, Digitale, StarFounder è la community su scala globale per chi vuole costruire e far crescere la propria idea imprenditoriale',
        'StarFounder.com': 'StarFounder.com',
        'How we make our digital solutions and skills available to passionate founders': 'Il modo in cui mettiamo a disposizione di giovani founders le nostre soluzioni digitali e competenze',
        'StarFounder Factory': 'StarFounder Factory',
        'We are a tech company focused on providing a support network of Start-up communities and Entrepreneurs who believe in our mission and values': 'Siamo una tech company dedicata a supportare i player dell\'ecosistema globale che credano nella nostra missione e nei nostri stessi valori',
        '#staytuned with our newsletter': 'Registrati alla nostra newsletter',
        'Subscribe': 'Iscriviti',
        'Discover the most exciting ideas and startups': 'Scopri le più interessanti idee e startups',
        'Startup': 'Startup',
        'Profile': 'Profile',
        'Thanks ': 'Grazie ',
        'Already registered!': 'La tua mail è già registrata',
        'Continue': 'Continua',
        'To continue you have to answer all questions': 'Per continuare è necessario rispondere a tutte le domande',
        'Complete': 'Completa',
        'We still need some more info...': 'Ci servono ancora alcune info...',
        'Choose here' : 'Scegli qui',
        'Publish your pitch': 'Pubblica il tuo pitch',
        'We carried out a preliminary assessment of your idea.': 'Abbiamo effettuato una valutazione preliminare della tua idea.',
        'Register to discover your full profile and post it in the common area.': 'Registrati per scoprire il tuo profilo completo e pubblicarlo nell\'area comune.',
        'Register to discover your complete profile and post your 1 Minute Pitch':'Registrati per scoprire il tuo profilo completo e pubblicare il tuo 1 Minute Pitch',
        'Within a few days there will be an opportunity to connect with partners in the area who can help you develop your idea.': 'Entro pochi giorni ci sarà la possibilità di entrare in contatto con dei partner presenti sul territorio che potranno aiutarti a sviluppare la tua idea.',
        'You are ' : ' Tu sei ',
        'Partner available': 'Partner disponibili',
        'Sign up to complete your profile': 'Registrati per completare il tuo profilo',
        'DISCOVER ALL PARTNERS':'SCOPRI TUTTI PARTNERS',
        'Sign In': 'Accedi',
        'Sign up': 'Registrati',
        'Why subscribe?' : 'Perché iscriversi?',
        'By creating an account, you agree to our': 'Con la creazione di un account accetti  i nostri',
        'Terms & Conditions': 'Termini e Condizioni',
        'We still need some info...' : 'Ci siamo quasi! Completa le ultime domande e scopri il tuo profilo...',
        'Here your results:':'Qui trovi i tuoi risultati:',
        'Congrats ': 'Congratulazioni ',
        'your evaluation': 'la tua valutazione',
        'Contact information': 'Contatti',
        'Back': 'Indietro',
        'Start!': 'Inizia!',
        'OOPS! Something went wrong. Try again later.' :'OPS! Qualcosa è andato storto. Riprova più tardi.',

        'StarFounder wants to grow more and more and we are really glad that you have highlighted us a new innovation hub!':'la nostra community vuol crescere sempre di più e siamo contenti che tu ci abbia indicato un nuovo hub di innovazione!',
        'We will work as soon as possible to identify the main partners based in':'Lavoreremo al più presto per identificare i principali partners che operano in',
        'In the meantime, if you want to help us or learn more about it, you can use hello@starfounder.com':'Nel mentre se vuoi aiutarci o saperne di più puoi contattarci scrivendo a hello@starfounder.com',

        'Partners':'Partners',

        'We have selected for you':'Abbiamo selezionato per te',
        'partners among':'partners su',
        'based in':'dislocati in',
        'that fit with':'che operano nel settore di',

        'Thanks to your answers we have generated the 1 Minute Pitch of':'Grazie alle tue risposte abbiamo generato il 1 Minute Pitch di',
        'Sign up for more details':'Registrati per vedere i dettagli',
        'Don\'t have an account?':'Non hai un account?',
        'Click here':'Clicca qui',
        'to start our questionnaire':'per iniziare il nostro questionario',
    },
});

export default {}
