<template>
  <div>
    <header>
      <div class="top-nav">
        <div class="top-container">
          <img @click="goToHome" src="@/assets/Vector.png" style="cursor: pointer" class="bf-icon"/>
          <div class="row align-items-center"
               :style="completeComputeQuestionnaire.length === 0 ? 'display:none;' : ''">
            <div style="color: white; margin-right: 10px">
              {{ firstUppercase(this.$store.state.user.name) + ' ' + firstUppercase(this.$store.state.user.surname) }}
            </div>
            <div class="nameCircle">
              {{
                this.$store.state.user.name.charAt(0).toUpperCase() + this.$store.state.user.surname.charAt(0).toUpperCase()
              }}
            </div>
            <div class="row ml-5 align-items-center onLogout" @click="logout">
              <img src="@/assets/logout.png" alt="logout" class="logout-icon">
              <p class="logout-text" style="margin-left: 8px;">Logout</p>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main v-if="completeComputeQuestionnaire.length !== 0">

      <section class="intro-container">
        <b-tabs class="tab-nav-dashboard nav-tab-a text-white-50" content-class="mt-3"
                active-nav-item-class="text_dark" style="font-size: 18px; ">
          <b-tab class="text-white" :title="tabLabel.evaluation" active>
            <b-card class="bcard-container">
              <p class="your-evaluation-text">{{ t('your evaluation') }}</p>
              <b-card-body class="card-body-internal">
                <div class="d-flex">
                  <div v-for="(badge, keyBadge) in this.resultCompleteComputeQuestionnaire.badges"
                       :key="keyBadge">
                    <img v-if="badge === 'Founder Stage'" src="@/assets/FounderStage.png"
                         class="fn-image assets-icon" alt="founder-stage">
                    <p style="font-size: 10px;margin-top: 0;text-align: center;margin-bottom: 0"
                       v-if="badge === 'Founder Stage'">Founder<br>Stage</p>

                    <img v-if="badge === 'Top Idea'" src="@/assets/TopIdea.png" class="fn-image assets-icon"
                         alt="top-idea">
                    <p style="font-size: 10px; margin-top: 0;text-align: center;margin-bottom: 0"
                       v-if="badge === 'Top Idea'">Top<br>Idea</p>

                    <img v-if="badge === 'Great Feasibility'" src="@/assets/GreatVisibility.png"
                         class="fn-image assets-icon" alt="great-feasibility">
                    <p style="font-size: 10px;margin-top: 0;text-align: center;margin-bottom: 0"
                       v-if="badge === 'Great Feasibility'">Great<br>Feasibility</p>

                    <img v-if="badge === 'Bold Team'" src="@/assets/BoldTeam.png" class="fn-image assets-icon">
                    <p style="font-size: 10px;margin-top: 0;text-align: center;margin-bottom: 0"
                       v-if="badge === 'Bold Team'">Bold<br>Team</p>

                    <img v-if="badge === 'Seed Stage'" src="@/assets/SeedStage.png" class="fn-image assets-icon">
                    <p style="font-size: 10px;margin-top: 0;text-align: center;margin-bottom: 0"
                       v-if="badge === 'Seed Stage'">Seed<br>Stage</p>

                    <img v-if="badge === 'Growth Stage'" src="@/assets/GrowthStage.png" class="fn-image assets-icon">
                    <p style="font-size: 10px;margin-top: 0;text-align: center;margin-bottom: 0"
                       v-if="badge === 'Growth Stage'">Growth<br>Stage</p>

                  </div>

                </div>
                <h2 class="congrats-text">{{ t('Congrats ') }} {{
                    firstUppercase(this.$store.state.user.name) + '!'
                  }}</h2>
                <p>{{ checkLanguage(this.resultCompleteComputeQuestionnaire.quickValuation) }}
                </p>
                <hr class="separator">
                <div class="flex-container">
                  <div class="flex-item-first">
                    <div class="flex-item-content-first">
                      <div class="card-content-title">
                        {{ checkLanguage(this.resultCompleteComputeQuestionnaire.quickFeedback) }}
                      </div>
                    </div>
                    <section class="intro-container row justify-content-center pb-4 pt-5">
                      <div class="col">
                        <div class="row justify-content-center mb-5 text-center">
                          <div class="container text-white position-absolute justify-content-center" style="top: -50px">
                            <div class="circTxtSec" id="test"></div>
                          </div>
                          <vue-ellipse-progress
                              :progress="this.finalUserType()"
                              :angle="-90"
                              :color="gradient"
                              :legend="false"
                              dash="strict 5 0.98"
                              :emptyColor="emptyColor"
                              :emptyColorFill="emptyColorFill"
                              :size="300"
                              thickness="2%"
                              emptyThickness="5%"
                              animation="default 1500 1500"
                              line-mode="bottom 10"
                              fontColor="white"
                              fontSize="3rem">
                            <p class="mb-0" style="text-align: center; font-size: 1rem;" slot="legend-caption">Your
                              Stage:</p>
                            <p class="mt-0" style="text-align: center; font-size: 3rem;" slot="legend-caption">
                              {{ this.$store.state.elaborateGuestResponse.userType }}</p>
                          </vue-ellipse-progress>

                        </div>
                      </div>
                    </section>

                  </div>
                  <div style=" min-width: 50%; height: auto;">
                    <div class="flex-item-second">
                      <div class="flex-item-content-second">
                        <div class="flex-item-content-title">{{
                            nextStepTitleSplit(checkLanguage(this.resultCompleteComputeQuestionnaire.nextStep))
                          }}
                        </div>
                        <div
                            v-for="(item, key) in nextStepSplit(checkLanguage(this.resultCompleteComputeQuestionnaire.nextStep))"
                            :key=key class="row" style="margin: 20px 0 0 0; ">
                          <div class="numberCircle">{{ key + 1 }}</div>
                          <div class="col text-solution" v-html="item">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-tab>

          <b-tab class="text-white" :title="tabLabel.ecosystem">
            <div class="bcard-container">
              <div class="p-2 ">
                <div class="container-fluid nav-tab-a p-0">
                  <nav class="tabbable">
                    <div class="nav nav-tabs" id="myBtnContainer" role="tablist">
                      <a v-for="(partner, index) in this.$store.state.partnersList" v-bind:key="index"
                         :class="partner === 'All' ? 'active': ''"
                         class="nav-item nav-link btn-nav" id="nav-all-tab" data-toggle="tab"
                         role="tab" aria-controls="nav-home" aria-selected="false"
                         v-on:click="filterSelection(partner)">{{ partner }}</a>
                    </div>
                  </nav>
                </div>
              </div>

              <div v-if="filteredListPage.length > 0">
                <div class=" d-flex flex-wrap row-cols-sm-1 row-cols-md-2 card-deck p-2">
                  <CardEcosystem v-for="(item, key) in filteredListPage[page]" :key="key"
                                 v-bind:category="item.category"
                                 v-bind:email="item.city"
                                 v-bind:name="item.name"
                                 v-bind:website="item.link"
                                 class="filterDiv"/>
                </div>
              </div>

              <div v-else>
                <p>Hey {{ this.$store.state.user.name }}, {{
                    t('StarFounder wants to grow more and more and we are really glad that you have highlighted us a new innovation hub!')
                  }}</p>
                <p>{{ t('We will work as soon as possible to identify the main partners based in') }} nome altro Hub
                  inserito dall'utente.</p>
                <p>
                  {{
                    t('In the meantime, if you want to help us or learn more about it, you can use hello@starfounder.com')
                  }}</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="arrow-back-text col-1 p-0 justify-content-center" v-on:click="page<1?false:page--">
                <img src="@/assets/icon-back.svg" class="arrow-icon align-items-center" alt="back"/>
              </div>
              <div class="row align-items-center">{{ this.page + 1 + ' / ' + this.filteredListPage.length }}</div>
              <a class="arrow-back-text col-1 p-0 justify-content-center"
                 v-on:click="page<filteredListPage.length-1?page++:false">
                <img src="@/assets/icon-back.svg" class="next-icon align-items-center" alt="next"/>
              </a>
            </div>
          </b-tab>
          <b-tab class="text-white" :title="tabLabel.publicPitch">
            <div class="bcard-container p-2 row">
              <!--<b-tab class="text-white " :title="tabLabel.yourPitch">-->
              <h2 class="text-center title-public-pitch w-100">
                {{ firstUppercase(this.completeComputeQuestionnaire.name) }} Elevator Pitch</h2>
              <div class="your-pitch-container col-12 d-flex flex-wrap"
                   v-html="yourPitchSplit(completeComputeQuestionnaire.oneMinutePitch.text)"/>


              <!-- </b-tab>-->
              <!-- <b-tab class="text-white" :title="tabLabel.otherInformations ">
                 <div class="other-information-container">
                   <div class="d-flex justify-content-between align-items-center" style="margin-top: 30px;">
                     <div class="row">
                       <div class="circle-project">
                         <img src="@/assets/plus-circle.svg" style="margin-left: 33px; margin-top: 33px" alt=""></div>
                       <h2 style="align-self: center">{{ this.completeComputeQuestionnaire.name }}</h2>
                     </div>
                     <div class="row">
                       <div style="margin-right: 30px">Theme colors</div>
                       <div class="circle-theme"></div>
                     </div>
                   </div>
                   <hr style="background-color: #303030; margin-top: 30px">
                   <div>
                     <p class="cont-info">{{ t('Contact information') }}</p>
                     <div class="row flex-md-nowrap">
                       <div style="padding: 0 !important;" class="box-project col-md-6 col-sm-12 mr-md-2 mb-sm-3">

                         <img src="@/assets/globe2.svg" class="mr-2 ml-2 " alt="globe"/>
                         <div class="w-100 no-focus" contentEditable=true :data-text="label.projectWebSite"></div>
                       </div>
                       <div style="padding: 0 !important;" class="box-project col-md-6 col-sm-12">
                         <img src="@/assets/mailbox.svg" class="mr-2 ml-2" alt="globe"/>
                         <div class="w-100 no-focus" contentEditable=true :data-text="label.projectEmail"></div>
                       </div>
                     </div>
                   </div>


                   <p class="cont-info">Team information</p>
                   <div class="row flex-md-nowrap">
                     <div style="padding: 0 !important;" class="box-project col-md-3 col-sm-12 mr-md-2 mb-sm-3">

                       <img src="@/assets/user.svg" class="mr-2 ml-2 " alt="globe"/>
                       <div class="w-100 no-focus" contentEditable=true :data-text="label.nameAndSurname"></div>
                     </div>
                     <div class="box-project col-md-9 col-sm-12">
                       <div class="w-100 no-focus" contentEditable=true :data-text="label.role"></div>
                     </div>
                   </div>

                   <hr style="background-color: #303030; margin-top: 30px">

                   <div style="margin-bottom: 16px">01 — Question: lorem ipsum dolor sit amet?</div>
                   <div class=" box-text-field d-flex">
                     <img src="@/assets/pen.svg" style="height: fit-content;margin-top: 9px;" class="mr-2 ml-2"
                          alt="globe"/>
                     <div class=" no-focus" :data-text="label.yourTextHere" contentEditable=true>
                     </div>
                   </div>
                   <div style="margin-bottom: 16px">02 — Question: lorem ipsum dolor sit amet?</div>
                   <div class=" box-text-field d-flex">
                     <img src="@/assets/pen.svg" style="height: fit-content;margin-top: 9px;" class="mr-2 ml-2"
                          alt="globe"/>
                     <div class=" no-focus" :data-text="label.yourTextHere" contentEditable=true>
                     </div>
                   </div>
                 </div>


               </b-tab>-->

            </div>
            <button class="btn-pitch col-12" v-on:click="modal()">{{ t('Publish your pitch') }}</button>
            <modal-alert ref="modalAlert"
                         message="Stiamo sviluppando questa funzione. Ti contatteremo appena sarà disponibile"></modal-alert>
          </b-tab>
        </b-tabs>
      </section>

    </main>
    <div v-else>
      <no-services-available></no-services-available>
    </div>
    <footer style="background-color: black; height: 50px; bottom: 0;margin-left: 20px">
      <div class="intro-container">
        <img class="pt-3 cubeimg" src="@/assets/logo_starfounder.svg">
      </div>
    </footer>
    <loader style="z-index: 9999999" v-if="this.loader" object="#f7b847" color1="#ffffff" color2="#17fd3d" size="6"
            speed="2" bg="#343a40"
            objectbg="#999793" opacity="80" :disableScrolling="false" name="dots"></loader>
  </div>
</template>

<script>
import CardEcosystem from "../components/CardEcosystem";
import NoServicesAvailable from "../components/NoServicesAvailable";
import modalAlert from "../components/modalAlert";

export default {
  name: 'Dashboard',
  components: {NoServicesAvailable, CardEcosystem, modalAlert},
  data: function () {
    return {
      loader: false,
      titleNextStep: [],
      nextStep: [],
      completeComputeQuestionnaire: [],
      resultCompleteComputeQuestionnaire: [],
      activeCard: 1,
      categoryElement: {},
      isActive: false,
      filteredList: [],
      filteredListPage: [],
      page: 0,
      tabLabel: {
        evaluation: '',
        ecosystem: '',
        publicPitch: 'Public pitch',
        yourPitch: '',
        otherInformations: '',
      },
      label: {
        projectWebSite: '',
        projectEmail: '',
        nameAndSurname: '',
        role: '',
        yourTextHere: '',
      },
      gradient: {
        radial: false,
        colors: [
          {
            color: '#fff',
            offset: "0",
            opacity: '1',
          },
          {
            color: '#FDB827',
            offset: "100",
            opacity: '1',
          },
        ]
      },
      emptyColor: {
        radial: false,
        colors: [
          {
            color: '#FDB827',
            offset: 1,
            opacity: '0.2',
          },
          {
            color: '#FDB827',
            offset: 20,
            opacity: '0.4',
          },
          {
            color: '#FDB827',
            offset: 40,
            opacity: '0.6',
          }, {
            color: '#FDB827',
            offset: 80,
            opacity: '0.8',
          }, {
            color: '#FDB827',
            offset: 100,
            opacity: '1',
          },
        ]
      },
      emptyColorFill: {
        radial: true,
        colors: [
          {
            color: "#FDB827",
            offset: "50",
            opacity: "0.2",
          },
          {
            color: "#FDB827",
            offset: "50",
            opacity: "0.15",
          },
          {
            color: "#FDB827",
            offset: "70",
            opacity: "0.15",
          },
          {
            color: "#FDB827",
            offset: "70",
            opacity: "0.1",
          },
          {
            color: "#FDB827",
            offset: "90",
            opacity: "0.1",
          },
          {
            color: "transparent",
            offset: "90",
            opacity: "0.1",
          },
          {
            color: "transparent",
            offset: "95",
            opacity: "0.1",
          },
          {
            color: "transparent",
            offset: "95",
            opacity: "0.1",
          },
        ],
      },
    }
  },
  created() {
    let self = this;
    if (self.$store.state.languageActive === "IT") {
      self.$translate.setLang('italian');
      self.tabLabel.evaluation = 'Valutazione';
      self.tabLabel.ecosystem = 'Ecosistema';
      self.tabLabel.yourPitch = 'Il tuo pitch';
      self.tabLabel.otherInformations = 'Altre informazioni';
      self.label.projectWebSite = 'Sito progetto';
      self.label.projectEmail = 'Email progetto';
      self.label.nameAndSurname = 'Nome e cognome';
      self.label.role = 'Ruolo';
      self.label.yourTextHere = 'Scrivi qui';
    } else {
      self.$translate.setLang('english');
      self.tabLabel.evaluation = 'Evaluation';
      self.tabLabel.ecosystem = 'Ecosystem';
      self.label.projectWebSite = 'Project Website';
      self.label.projectEmail = 'Project Email';
      self.label.nameAndSurname = 'Name and Surname';
      self.label.role = 'Role';
      self.label.yourTextHere = 'Your text here';
    }
  },
  mounted() {
    let self = this;
    self.filterSelection("All");
    self.completeComputeQuestionnaire = this.$store.state.completeComputeQuestionnaire;
    self.resultCompleteComputeQuestionnaire = this.$store.state.resultCompleteComputeQuestionnaire;
    setTimeout(() => {
      self.circularText(" Newbie  Low  Medium  High   Top ", 170, 0);
    });
  },

  methods: {
    circularText(txt, radius, classIndex) {
      txt = txt.split("");
      let circ = document.getElementsByClassName("circTxtSec")[classIndex];
      let deg = 360 / txt.length,
          origin = 0;
      txt.forEach((ea) => {
        ea = `<p style='height:${radius}px;position:absolute;transform:rotate(${origin}deg);transform-origin:0 100%'>${ea}</p>`;

        circ.innerHTML += ea;
        origin += deg;
      });
    },
    finalUserType() {
      if (this.$store.state.elaborateGuestResponse.userType === 'SEED') {
        return Math.round(this.$store.state.elaborateGuestResponse.valueQuestionnaire.seed)
      } else if (this.$store.state.elaborateGuestResponse.userType === 'FOUNDER') {
        return Math.round(this.$store.state.elaborateGuestResponse.valueQuestionnaire.founder);
      } else if (this.$store.state.elaborateGuestResponse.userType === 'GROWTH') {
        return this.$store.state.elaborateGuestResponse.valueQuestionnaire.later
      } else {
        return 0
      }
    },
    modal() {
      this.$refs.modalAlert.showModal();
    },
    checkLanguage(title) {
      if (this.$store.state.languageActive === 'EN') {
        return title.en;
      } else {
        return title.it;
      }
    },
    nextStepTitleSplit(el) {
      let total = el.split(new RegExp("[0-9]*[)]+[' ']"));
      this.titleNextStep = total[0];
      this.nextStep = total;
      return total[0];
    },
    nextStepSplit(text) {
      let total = text.split(new RegExp("[0-9]*[)]+[' ']"));
      total.shift();
      return total;
    },
    goToHome() {
      this.$router.push('/');
    },
    yourPitchSplit(yourPitch) {
      yourPitch = this.checkLanguage(yourPitch);
      let pitchEndcap = '<ENDCAP>' + yourPitch;
      for (let i = 1; i <= 5; i++) {
        if (i === 1) {
          pitchEndcap = pitchEndcap.replace('<ENDCAP>', '<div class="row p-3"><img class="col-xl-2 col-sm-12 align-center" src="images/problem.svg" width="100" height="100" alt="problem"/><div class="col-xl-10 col-sm-12 p-0"><h2 class="p-0 title-public-pitch">Problem</h2>');
        } else if (i === 2) {
          pitchEndcap = pitchEndcap.replace('<ENDCAP>', '</div></div><div class="row p-3"><img class="col-xl-2 col-sm-12 align-center" src="images/team.svg" width="100" height="100"  alt="team"/><div class="col-xl-10 col-sm-12 p-0"><h2 class="p-0 title-public-pitch">Team</h2>');
        } else if (i === 3) {
          pitchEndcap = pitchEndcap.replace('<ENDCAP>', '</div></div><div class="row p-3"><img class="col-xl-2 col-sm-12 align-center" src="images/solution.svg" width="100" height="100"  alt="solution"/><div class="col-xl-10 col-sm-12 p-0"><h2 class="p-0 title-public-pitch">Solution</h2>');
        } else if (i === 4) {
          pitchEndcap = pitchEndcap.replace('<ENDCAP>', '</div></div><div class="row p-3"><img class="col-xl-2 col-sm-12 align-center" src="images/stage.svg" width="100" height="100"  alt="stage"/><div class="col-xl-10 col-sm-12 p-0"><h2 class="p-0 title-public-pitch">Stage</h2>');
        } else if (i === 5) {
          pitchEndcap = pitchEndcap.replace('<ENDCAP>', '</div></div><div class="row p-3"><img class="col-xl-2 col-sm-12 align-center" src="images/goal.svg" width="100" height="100"  alt="goal"/><div class="col-xl-10 col-sm-12 p-0"><h2 class="p-0 title-public-pitch">Goal</h2>');
        }
      }
      pitchEndcap = pitchEndcap + '</div></div>';
      return pitchEndcap;
    },
    changePageFilter() {
      let self = this;
      self.page = 0;
      var tempArr = [];
      self.filteredListPage = [];
      for (let i = 0; i < this.filteredList.length; i++) {
        tempArr.push(this.filteredList[i])
        if (tempArr.length === 8 || i === this.filteredList.length - 1 && tempArr !== []) {
          self.filteredListPage.push(tempArr);
          tempArr = [];
        }
      }
    },
    logout() {
      this.loader = true;
      this.$router.push('/');
      this.$store.commit("logoutMutation");
      this.loader = false;
    },
    filterSelection(val) {
      let self = this;
      self.filteredList = [];
      if (val === "All") {
        self.filteredList = self.$store.state.completeComputeQuestionnaire.partners;

      } else {
        for (let i = 0; i < self.$store.state.completeComputeQuestionnaire.partners.length; i++) {
          if (val === self.$store.state.completeComputeQuestionnaire.partners[i].category)
            self.filteredList.push(self.$store.state.completeComputeQuestionnaire.partners[i]);
        }
      }
      self.changePageFilter();
    },
    linkFormatter(link) {
      let newLink = link.replace(/(^\w+:|^)\/\//, '');
      let newLinkSearch = newLink.search("www");
      if (newLinkSearch === -1) {
        newLink = "www." + newLink;
      }
      return newLink;
    },
    firstUppercase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
  }
}
</script>
<style>
.row {
  margin: 0px;
}

div.circTxtSec {
  display: inline-block;
  margin-bottom: 128px;
  color: whitesmoke;
}

@media (max-width: 50em) {
  footer {
    width: 100%;
  }
}
</style>
